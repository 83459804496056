import "./ModalFIxed.scss";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export default function ModalFixed(props: {
  show: boolean;
  children: any;
  title: string;
  hideClose?: boolean;
  onClose?: any;
}) {
  return (
    <Dialog open={props.show}>
      <DialogTitle>
        <div className="modal-fixed-title-container">
          <Typography variant="h6">{props.title}</Typography>
          {(props.onClose && !props.hideClose )? (
            <IconButton aria-label="close" onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </div>

      </DialogTitle>
      <Divider light />
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  );
}
