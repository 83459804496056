import "./App.scss";
import { Worker } from "@react-pdf-viewer/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { configAmlAxios } from "./amlInstance";
import thunk from "redux-thunk";
import AmlReducer from "./core/store/reducers/aml";
import UIReducer from "./core/store/reducers/UI";

import Layout from "./hoc/Layout";

const rootReducer = combineReducers({
  aml: AmlReducer,
  UI: UIReducer,
});

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

configAmlAxios(store);

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "rgba(42, 128, 181, 1)",
      contrastText: "white",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <Provider store={store}>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <BrowserRouter>
              <CssBaseline />
              <Layout />
            </BrowserRouter>
          </Worker>
        </Provider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
