import "./CellTableRoles.scss";
import { Fragment } from "react";
import { Trans } from "react-i18next";
import { CompanyMember } from "../../core/types/entity/CompanyMember";
import { Tooltip } from "@material-ui/core";
import Spacer from "../Spacer/Spacer";

export default function CellTableRoles(props: { member: CompanyMember }) {

  return (
    <div className="roles-tag-container">
      {props.member.executor && (
        <Fragment>
          <Tooltip title="Esecutore" placement="top-start" arrow>
            <div className="tag-role">
              <span className="tag-label">
                <Trans>MEMBERS_TABLE.TAGS.EXECUTOR</Trans>
              </span>
            </div>
          </Tooltip>
          <Spacer />
        </Fragment>
      )}

      {props.member.isRepresentative && (
        <Fragment>
          <Tooltip title="Rappresentante Legale" placement="top-start" arrow>
            <div className="tag-role">
              <span className="tag-label">
                <Trans>MEMBERS_TABLE.TAGS.REPRESENTATIVE</Trans>
              </span>
            </div>
          </Tooltip>
          <Spacer />
        </Fragment>
      )}
      {props.member.isHolder && (
        <Fragment>
          <Tooltip title="Titolare Effettivo" placement="top-start" arrow>
            <div className="tag-role">
              <span className="tag-label">
                <Trans>MEMBERS_TABLE.TAGS.HOLDER</Trans>
              </span>
            </div>
          </Tooltip>
          
        </Fragment>
      )}
    </div>
  );
}
