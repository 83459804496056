import Tooltip from "@material-ui/core/Tooltip";
import "./TooltipColor.scss";

export default function TooltipColor(props: {
  title: string;
  children?: any;
  color?: string;
  tooltipClass?: string;
}) {
  return (
    <Tooltip placement="top-start" title={props.title} arrow>
      <div
        className={`tooltip ${props.color ? props.color : "default"} ${
          props.tooltipClass ? props.tooltipClass : " "
        }`}
      >
        {props.children}
      </div>
    </Tooltip>
  );
}
