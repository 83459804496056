import { Fragment, MutableRefObject, useEffect, useRef, useState } from "react";
import { CHART_TYPE } from "../../core/constants/ChartType";
import { CompanyPerMonths } from "../../core/types/entity/DashboardData";
import {
  ChartConfiguration, ChartConfigurationBuilder
} from "../../core/types/UI/ChartConfiguration";
import {StringifyDateTime} from "../../core/utilities/formatters";
import ChartData from "../ChartData/ChartData";

export default function ClientsForMonthsChart(props: {
  datasource?: CompanyPerMonths[];
}) {
  const chartRef: MutableRefObject<any> = useRef();
  const tooltipRef: MutableRefObject<any> = useRef();

  const [configuration, setConfiguration] = useState<ChartConfiguration>();

  useEffect(() => {
    if (props.datasource && props.datasource.length > 0) {

      const chartInterval = props.datasource.map(item =>  StringifyDateTime(item.month,"MMM-YYYY"));
      const chartDataSource = [{
        data: props.datasource.map(item => item.number),
        label: 'Nuovi Iscritti'
      }];

      let conf: ChartConfiguration = ChartConfigurationBuilder.createBarChart(CHART_TYPE.SINGLE_BAR, chartDataSource, chartInterval);

      const maxItem = props.datasource.reduce((prev, current) =>
          prev.number > current.number ? prev : current
      )
      const max = maxItem ? maxItem.number : 0;
      conf.yAxes.maxValue = max > 10 ? max : 10;
      conf.yAxes.stepSize =  Math.round(conf.yAxes.maxValue / 5);

      setConfiguration(conf);
    }
  }, []);

  return (
    <Fragment>
        {configuration && <ChartData
          chartRef={chartRef}
          tooltipRef={tooltipRef}
          configuration={configuration}
        />}
    </Fragment>
  );
}
