import ToastMessage from "../../core/types/UI/ToastMessage";
import ToastMessageItem from "../ToastMessageItem/ToastMessageItem";

export default function FeedbackHandler(props: {
  toastMessage: ToastMessage;
  onClose?: any;
}) {
  return (
    <div>
      {props.toastMessage && props.toastMessage.message && (
        <ToastMessageItem
          toastMessage={props.toastMessage}
          onClose={props.onClose}
        />
      )}
    </div>
  );
}
