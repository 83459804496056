import "./MembersTableStyle.ts";
import {membersColumns} from './MembersSchema';
import DataTable from "react-data-table-component";
import { membersTableStyle } from './MembersTableStyle';
import {CompanyMember} from '../../core/types/entity/CompanyMember';
import { TableConfig } from '../../core/types/UI/TableConfig';
import { useTranslation } from "react-i18next";

export default function MemberTable(props: {
    onHistoryClick?: any;
    dataSource?: any;
    tableConfig?: TableConfig | undefined;
}) {
    const {t} = useTranslation();
    const showHistory = (row: CompanyMember) => {
        props.onHistoryClick(row);
    }

    return (
            <DataTable
            responsive
            data={props.dataSource}
            columns={membersColumns(showHistory, props.tableConfig, t)}
            customStyles={membersTableStyle}
          />
    )
}
