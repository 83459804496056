import { hideUiItem } from './../UI/UI';
import * as actionTypes from "../../../types/actionTypes";
import DocumentCompany from "../../../types/DocumentCompany";
import { Company } from "../../../types/entity/Company";
import {
  CompanyMember,
  MemberHistory,
} from "../../../types/entity/CompanyMember";
import DashboardData from "../../../types/entity/DashboardData";
import { ITEM_UI } from '../../../constants/ItemUI';

export const getDashboardDataSuccess = (dashboard: DashboardData) => {
  return {
    type: actionTypes.SET_DASHBOARD_SUCCESS,
    dashboard,
  };
};

export const getCompaniesSuccess = (companies: Company[]) => {
  return {
    type: actionTypes.SET_COMPANIES_SUCCESS,
    companies,
  };
};

export const getUpdatedCompaniesSuccess = (companies: Company[]) => {
  return {
    type: actionTypes.SET_UPDATED_COMPANIES_SUCCESS,
    companies,
  };
};

export const getOldCompaniesSuccess = (companies: Company[]) => {
  return {
    type: actionTypes.SET_OLD_COMPANIES_SUCCESS,
    companies,
  };
};

export const deleteCompanySuccess = (companyId: number) => {
  return {
    type: actionTypes.DELETE_COMPANY_SUCCESS,
    companyId,
  };
};

export const getHistorySuccess = (
  companyId: number,
  registry: CompanyMember,
  history: MemberHistory[]
) => {
  return {
    type: actionTypes.SET_HISTORY_SUCCESS,
    payload: {
      companyId,
      registry,
      history,
    },
  };
};

export const getCompanyMembersSuccess = (members: CompanyMember[]) => {
  return {
    type: actionTypes.SET_COMPANY_MEMBER_SUCCESS,
    members,
  };
};

export const setValidationSuccess = () => {
  return {
    type: actionTypes.SET_VALIDATION_SUCCESS,
  };
};

export const setDataSuccess = (status: boolean) => {
  return {
    type: actionTypes.SET_DATA_SUCCESS,
    status,
  };
};

export const setChamberViewSuccess = (
  document: DocumentCompany
) => {
  return {
    type: actionTypes.SET_CHAMBER_VIEW_SUCCESS,
    document,
  };
};

export const getDocumentCompanySuccess = (document: DocumentCompany) => {
  return {
    type: actionTypes.SET_DOCUMENT_COMPANY_SUCCESS,
    document
  };
}

export const sendEscalationEmailSuccess = (status: boolean) => {
  return {
    type: actionTypes.SEND_ESCALATION_SUCCESS,
    escalationEmailStatus: status
  }
}

