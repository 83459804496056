import axios from "axios";
import { CUSTOM_HEADER } from "./core/constants/CustomHeaders";
import { ITEM_UI } from "./core/constants/ItemUI";
import {
  hideUiItem,
  showUiItem,
  setErrors,
} from "./core/store/actions/UI/UI";
import {
  mapErrorItemToErrorMessage,
  mapErrorMessage,
} from "./core/types/UI/ErrorMessage";

let reduxStore;
let amlInstance = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL === ""
      ? window?.location?.origin
        ? window.location.origin
        : "/"
      : process.env.REACT_APP_API_URL,
});

export const axiosAll = (requests, callbackFunction, parameters) => {
  return axios.all(requests).then(
    axios.spread((...responses) => {
      callbackFunction(responses, parameters);
    })
  );
};

export const configAmlAxios = (store, url) => {
  if (url) {
    amlInstance.defaults.baseURL = url;
  }
  reduxStore = store;



  configInterceptor();
};

let requestNumber = 0;

const configInterceptor = () => {
  amlInstance.interceptors.request.use((req) => {
    const { dispatch } = reduxStore;

    const oktaToken = sessionStorage.getItem('okta-token-storage');
    if (oktaToken) {
      const token = JSON.parse(oktaToken);
      req.headers.Authorization = 'Bearer ' + token.accessToken.accessToken;
    }

    if (!req.headers[CUSTOM_HEADER.DISABLE_LOADING]) {
      requestNumber++;
      dispatch(showUiItem(ITEM_UI.LOADING));
    }
    return req;
  });

  amlInstance.interceptors.response.use(
    (res) => {
      const { dispatch } = reduxStore;
      if (requestNumber > 0) requestNumber--;

      if (requestNumber === 0) dispatch(hideUiItem(ITEM_UI.LOADING));

      if (res.data.status === "KO" && isErrorHandlerEnabled(res)) {
        dispatch(setErrors(mapErrorItemToErrorMessage(res.data.errors)));
        throw res;
      }
      return res;
    },
    (err) => {
      const { dispatch } = reduxStore;

      requestNumber--;

      if (requestNumber === 0) dispatch(hideUiItem(ITEM_UI.LOADING));

      if (isErrorHandlerEnabled(err)) {
        dispatch(
          setErrors(
            mapErrorMessage({
              errors: [
                {
                  code: err.response?.status,
                  description: err.response?.statusText,
                },
              ],
            })
          )
        );
      } else {
        return err.response;
      }
    }
  );
};

const isErrorHandlerEnabled = (res) => {
  return !res.config?.headers[CUSTOM_HEADER.DISABLE_ERROR_HANDLER];
};

amlInstance.defaults.headers.post["Content-Type"] = "application/json; charset=utf-8";
amlInstance.defaults.headers.post["Accept"] = "application/json; charset=utf-8";

export default amlInstance;
