//Currency
import moment from 'moment';

export const currencyFormatter = (value: any) => {
    if (value) {
        return (+value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else {
        return `-`
    }
}

//Date
export const ConvertToTimestamp = (date: any) => {
    return moment(date).format('x');
}

export const convertToDate = (timestamp: any, format?: any) => {
    return moment(timestamp, 'x').format(format ? format : 'L');
}

export const StringifyDateTime = (date: any, format?: any) => {
    return moment(date).format(format ? format : 'L');
}

//File
export const fileToBase64 = (file: any) => {
  return file.replace(/^data:.+;base64,/, '')
}
