import { Fragment } from "react";
import "./CompanyDocumentHandler.scss";
import { Trans, useTranslation } from "react-i18next";
import DocumentCompany, {
  DOCUMENT_COMPANY_TYPE,
} from "../../core/types/DocumentCompany";
import Spacer from "../Spacer/Spacer";
import { companyDocumentIcons } from "./CompanyDocumentIcons";
import DocumentPreview from "../DocumentPreview/DocumentPreview";
import { Company } from "../../core/types/entity/Company";
import Spinner from "../Spinner/Spinner";
import ModalFixed from "../ModalFixed/ModalFixed";

export default function CompanyDocumentHandler(props: {
  onDocumentSelected: any;
  company: Company;
  documentLoading: boolean;
  onClose: any;
  show: boolean;
  chamberView: DocumentCompany;
}) {
  const { t } = useTranslation();

  const onHandleClick = (documentType: DOCUMENT_COMPANY_TYPE) => {
    props.onDocumentSelected(documentType);
  };

  return (
    <ModalFixed
      onClose={props.onClose}
      show={props.show}
      hideClose={props.documentLoading}
      title={t(
        !props.documentLoading
          ? "COMPANIES_TABLE.COMPANY_DOCUMENTS_MODAL.TITLE"
          : "COMPANIES_TABLE.COMPANY_DOCUMENTS_MODAL.DOWNLOAD_TITLE"
      )}
    >
      <div className="company-document-handler-container">
        {!props.documentLoading ? (
          <Fragment>
            <span className="section-title">
              <Trans>COMPANY_DOCUMENT_HANDLER.CHAMBER_VIEW</Trans>
            </span>
            {props.chamberView && props.chamberView.fileContent && (
              <DocumentPreview
                companyVat={props.company.companyVat}
                vatNumber={props.company.vatNumber}
                document={props.chamberView}
              />
            )}
            {props.company && (
              <div className="company-document-handler-download-container">
                <Spacer direction="vertical" size="xxl" />
                <span className="section-title">
                  <Trans>COMPANY_DOCUMENT_HANDLER.OTHER_DOCUMENTS</Trans>
                </span>
                <Spacer direction="vertical" size="xl" />
                <div className="documents-container">
                  {companyDocumentIcons(t, props.company.hasSignaturePower)
                    ?.length > 0 &&
                    companyDocumentIcons(
                      t,
                      props.company.hasSignaturePower
                    ).map(
                      (documentIcon, index) =>
                        documentIcon.visible && (
                          <Fragment key={index}>
                            <Spacer size="xl" />
                            <div
                              className="company-document"
                              onClick={() =>
                                onHandleClick(documentIcon.documentType)
                              }
                            >
                              {documentIcon.icon}
                              <span className="document-name">
                                {documentIcon.name}
                              </span>
                            </div>
                            <Spacer size="xl" />
                          </Fragment>
                        )
                    )}
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          <Spinner />
        )}
        <Spacer direction="vertical" size="xxl" />
      </div>
    </ModalFixed>
  );
}
