import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import "./OktaResumeView.scss";
import {LOGOUT_REDIRECT_URI} from '../../okta';
import oktaLogo from '../../assets/images/okta.png'
import { Button } from "@material-ui/core";

export default function OktaResumeView() {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<any>(null);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        console.log(info);
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const logout = async() =>
    await oktaAuth.signOut();

  return (
    <div className="OktaContainer">

    <img src={oktaLogo} alt="okta" className="logo-okta"/>
      {userInfo && (
        <div className="user-card">
          <p className="title">{userInfo.name}</p>
          <p>
            Email: <span className="info">{userInfo.email}</span>
          </p>
          <p>
            Email verificata: 
            <span className="info">
              {userInfo.email_verified ? " Si " : " No "}
            </span>
          </p>
          <p>
            Locale: <span className="info">{userInfo.locale}</span>
          </p>
          <p>
            Zona: <span className="info">{userInfo.zoneinfo}</span>
          </p>
          </div>
      )}
      {authState.isAuthenticated && (
        <Button onClick={logout}>
          Logout
        </Button>
      )}
      {authState.isPending && !authState.isAuthenticated && !userInfo && (
        <p>Loading</p>
      )}
    </div>
  );
}
