import * as actionTypes from "../../types/actionTypes";
import { UIState } from "../../types/state/UIState";

export const initialUIState: UIState = new UIState();

const UIReducer = (state: UIState = initialUIState, action: any) => {
  switch (action.type) {
    case actionTypes.SHOW_LOADING: {
      return {
        ...state,
        showLoading: action.showLoading,
      };
    }
    case actionTypes.SHOW_DOCUMENT_LOADING: {
      return {
        ...state,
        documentLoading: action.documentLoading,
      };
    }
    case actionTypes.SHOW_MODAL: {
  
      return {
        ...state,
        showModal: action.showModal,
      };
    }
    case actionTypes.SHOW_TOAST: {
      return {
        ...state,
        toastMessage: action.toastMessage,
      };
    }
    case actionTypes.SET_ERRORS: {
      return {
        ...state,
        errors: [...state.errors, ...action.errors],
      };
    }
    case actionTypes.CLEAN_ERRORS: {
      return {
        ...state,
        errors: [],
      };
    }
    default:
      return state;
  }
};

export default UIReducer;
