import { Fragment, useEffect, useState } from "react";
import "./CompaniesView.scss";
import { connect } from "react-redux";
import * as actions from "../../core/store/actions";
import { Card } from "@material-ui/core";
import { Company } from "../../core/types/entity/Company";
import { AmlState } from "../../core/types/state/AmlState";
import { CompanyMember } from "../../core/types/entity/CompanyMember";
import { ModalMessage } from "../../core/types/UI/ModalMessage";
import DocumentCompany, { DOCUMENT_COMPANY_TYPE } from "../../core/types/DocumentCompany";

import Placeholder from "../../components/Placeholder/Placeholder";
import CompaniesTable from "../../components/CompaniesTable/CompaniesTable";
import Spacer from "../../components/Spacer/Spacer";
import HistoryView from "../History/HistoryView";
import Modal from "../../components/Modal/Modal";
import { companiesConfiguration } from "../../core/utilities/configurations";
import { downloadDocument } from "../../core/utilities/documentHandler";

function CompaniesView(props: {
  aml?: AmlState;
  onGetCompanies?: any;
  onGetHistory?: any;
  setSelectedRegistry?: any;
  onDelete?: any;
  onSetAtecoCode?: any;
  setDataSuccess?: boolean;
  onResetSetDataSuccess?: any;
  onGetChamberByCompany?: any;
  onDocumentSelected?: any;
  resetDocumentSelected: any;
  documentSelected?: DocumentCompany;
  chamberViewSelected: DocumentCompany;
  documentLoading: boolean;
  showDocumentLoading: boolean;
}) {
  const [modalMessage, setModalMessage] = useState<ModalMessage>({
    showModal: false,
  });

  useEffect(() => {
    props.onGetCompanies(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.documentSelected && props.documentSelected.fileContent) {
      downloadDocument(props.documentSelected);
      props.resetDocumentSelected();
    }
  }, [props.documentSelected]);

  useEffect(() => {
    if (props.aml?.selectedRegistry) onShowModal(props.aml?.selectedRegistry);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.aml?.selectedRegistry]);

  useEffect(() => {
    if (props.setDataSuccess) {
      props.onResetSetDataSuccess();
      props.onGetCompanies(true);
    }
  }, [props.setDataSuccess]);

  const onHistoryClick = (companyId: number, row: CompanyMember) => {
    props.onGetHistory(companyId, row);
  };

  const onShowModal = (row: CompanyMember) => {
    const modalMessageConfirm: ModalMessage = {
      showModal: true,
      modalTitle: `STORICO:${row.lastName} ${row.firstName}`,
      modalComponent: <HistoryView registry={row} />,
      onClose: () => props.setSelectedRegistry(),
    };
    setModalMessage(modalMessageConfirm);
  };

  const deleteCompany = (row: Company) => {
    props.onDelete(row.id);
  };

  const onDeleteCompany = (row: Company) => {
    const deleteCompanyConfirm: ModalMessage = {
      showModal: true,
      modalTitle: `Cancellazione cliente`,
      message: "Desideri cancellare il cliente indicato?",
      onConfirm: () => deleteCompany(row),
    };
    setModalMessage(deleteCompanyConfirm);
  };

  const onHandleSetAtecoCode = (id: number, atecoCode: any) => {
    props.onSetAtecoCode(id, atecoCode);
  };

  const onHandleChamberView = (id: number) => {
    props.onGetChamberByCompany(id);
  };
  return (
    <Fragment>
      <Modal modalMessage={modalMessage} />
      <Card className="companies-view-container">
        <Spacer direction="vertical" size="m" />
        <div className="companies-table-container">
          {props.aml &&
          props.aml.data?.companies &&
          props.aml.data?.companies.length > 0 ? (
            <CompaniesTable
              showDeleteCompany={true}
              onDeleteCompany={onDeleteCompany}
              onHistoryClick={onHistoryClick}
              onSetAtecoCode={(id: number, atecoCode: any) =>
                onHandleSetAtecoCode(id, atecoCode)
              }
              onHandleChamberView={(id: number) => onHandleChamberView(id)}
              tableConfig={companiesConfiguration}
              title="Clienti"
              companies={props.aml.data.companies}
              chamberView={props.chamberViewSelected}
              onDocumentSelected={(
                id: number,
                documentType: DOCUMENT_COMPANY_TYPE
              ) => props.onDocumentSelected(id, documentType)}
              documentLoading={props.documentLoading}
            />
          ) : (
            <Placeholder message="Nessuna azienda validata" />
          )}
        </div>
      </Card>
    </Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    aml: state.aml,
    setDataSuccess: state.aml.setDataSuccess,
    documentLoading: state.UI.documentLoading,
    chamberViewSelected: state.aml.chamberViewSelected,
    documentSelected: state.aml.documentSelected
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetCompanies: (status: boolean) => {
      dispatch(actions.getCompanies(status));
    },
    onGetHistory: (companyId: number, registry: CompanyMember) => {
      dispatch(actions.getHistory(companyId, registry));
    },
    setSelectedRegistry: (registry?: CompanyMember) => {
      dispatch(actions.setSelectedRegistry(registry));
    },
    onDelete: (companyId: number) => {
      dispatch(actions.deleteCompany(companyId));
    },
    onSetAtecoCode: (companyId: number, atecoCode: any) => {
      dispatch(actions.setAtecoCode(companyId, atecoCode));
    },
    onResetSetDataSuccess: () => {
      dispatch(actions.resetSetDataSuccess());
    },
    onGetChamberByCompany: (id: number) => {
      dispatch(actions.getChamberByCompany(id));
    },
    onDocumentSelected: (
      companyId: number,
      documentType: DOCUMENT_COMPANY_TYPE
    ) => {
      dispatch(actions.getDocumentCompany(companyId, documentType));
    },
    resetDocumentSelected: () => {
      dispatch(actions.resetDocumentSelected())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesView);
