import "./OktaLoginView.scss";
import {useHistory} from 'react-router-dom';
import { useOktaAuth } from "@okta/okta-react";
import OktaLogin from "../../components/OktaLogin/OktaLogin";
import { ROUTES } from "../../core/constants/Routes";
import LogoPrimaton from "../../assets/images/logo-primaton.svg";
import { useEffect } from "react";

export default function OktaLoginView() {

  const history = useHistory();

  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if(authState.isAuthenticated) {
        history.push(ROUTES.DASHBOARD);
    }
  }, [authState])

  return (
    <div className="okta-login-view">
      <img src={LogoPrimaton} alt="primaton" className="logo" />
      <OktaLogin nextRoute={ROUTES.DASHBOARD} />
    </div>
  );
}
