import Overlay from '../Overlay/Overlay';
import Spinner from '../Spinner/Spinner';

export default function OverlaySpinner(props: {
    showLoading?: boolean
}) {
    return (
        <Overlay showOverlay={props.showLoading}>
            <Spinner />
        </Overlay>
    )
}
