import { DOCUMENT_COMPANY_TYPE } from "../../core/types/DocumentCompany";
import THEME from "../../core/theme/theme";
import DocumentCompanyIcon from "../../core/types/DocumentCompanyIcon";
import GavelIcon from "@mui/icons-material/Gavel";
import ClassIcon from "@mui/icons-material/Class";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FileCopyIcon from '@mui/icons-material/FileCopy';

export const companyDocumentIcons = (t: any, hasPowerSignature: boolean | null) => {
  let icons: DocumentCompanyIcon[] = [
    {
      name: t("COMPANY_DOCUMENT_ICONS.SIGNATURE_POWER"),
      documentType: DOCUMENT_COMPANY_TYPE.SIGN_POWER,
      visible: hasPowerSignature,
      icon: <GavelIcon htmlColor={THEME.palette?.primary?.hover} fontSize="large"/>
    },
    {
      name: t("COMPANY_DOCUMENT_ICONS.CONTRACT"),
      documentType: DOCUMENT_COMPANY_TYPE.SIGNED,
      visible: true,
      icon: <ClassIcon htmlColor={THEME.palette?.primary?.hover} fontSize="large"/>
    },
    {
      name: t("COMPANY_DOCUMENT_ICONS.IDENTITIES"),
      documentType: DOCUMENT_COMPANY_TYPE.IDENTITIES,
      visible: true,
      icon: <AssignmentIndIcon htmlColor={THEME.palette?.primary?.hover} fontSize="large"/>
    },
    {
      name: t("COMPANY_DOCUMENT_ICONS.ALL"),
      documentType: DOCUMENT_COMPANY_TYPE.ALL,
      visible: true,
      icon: <FileCopyIcon htmlColor={THEME.palette?.primary?.hover} fontSize="large"/>
    },
  ];
  return icons;
};
