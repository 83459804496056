import "./VerificationView.scss";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../core/store/actions";
import { AmlState } from "../../core/types/state/AmlState";
import { Company } from "../../core/types/entity/Company";
import { verificationConfig } from "../../core/utilities/configurations";

import { Card } from "@material-ui/core";
import CompaniesTable from "../../components/CompaniesTable/CompaniesTable";
import Spacer from "../../components/Spacer/Spacer";
import Placeholder from "../../components/Placeholder/Placeholder";
import Modal from "../../components/Modal/Modal";
import { ModalMessage } from "../../core/types/UI/ModalMessage";
import { CompanyMember } from "../../core/types/entity/CompanyMember";
import HistoryView from "../History/HistoryView";
import DocumentCompany, { DOCUMENT_COMPANY_TYPE } from "../../core/types/DocumentCompany";
import { downloadDocument } from "../../core/utilities/documentHandler";

function VerificationView(props: {
  onAddCompany?: any;
  onRemoveCompany?: any;
  aml?: AmlState;
  onResetAmlState?: any;
  onGetCompanies?: any;
  onGetHistory?: any;
  setSelectedRegistry?: any;
  onValidateCompaniesStatus?: any;
  onDelete?: any;
  onSetAtecoCode?: any;
  setDataSuccess?: boolean;
  onResetSetDataSuccess?: any;
  onGetChamberByCompany?: any;
  onEscalation: any;
  sendEscalationSuccess: boolean;
  onDocumentSelected?: any;
  chamberViewSelected: DocumentCompany;
  documentSelected?: DocumentCompany;
  resetDocumentSelected: any;
  documentLoading: boolean;

}) {
  const [modalMessage, setModalMessage] = useState<ModalMessage>({
    showModal: false,
  });

  useEffect(() => {
    props.onResetAmlState();
    props.onGetCompanies(false);
  }, []);

  useEffect(() => {
    if (props.documentSelected && props.documentSelected.fileContent) {
      downloadDocument(props.documentSelected);
      props.resetDocumentSelected();
    }
  }, [props.documentSelected]);

  useEffect(() => {
    if (props.setDataSuccess === true) {
      props.onResetSetDataSuccess();
      props.onGetCompanies(false);
    }
  }, [props.setDataSuccess])

  useEffect(() => {

    if (props.aml?.validateStatusSuccess) {
      props.onResetAmlState();
      props.onGetCompanies(false);
    }
  }, [props.aml?.validateStatusSuccess]);

  useEffect(() => {
    if (props.aml?.selectedRegistry) onShowModal(props.aml?.selectedRegistry);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.aml?.selectedRegistry]);

  const deleteCompany = (row: Company) => {
    props.onDelete(row.id);
  };

  const onDeleteCompany = (row: Company) => {
    const deleteCompanyConfirm: ModalMessage = {
      showModal: true,
      modalTitle: `Cancellazione cliente`,
      message: "Desideri cancellare il cliente indicato?",
      onConfirm: () => deleteCompany(row),
    };
    setModalMessage(deleteCompanyConfirm);
  };

  const onHistoryClick = (companyId: number, row: CompanyMember) => {
    props.onGetHistory(companyId, row);
  };

  const onShowModal = (row: CompanyMember) => {
    const modalMessageConfirm: ModalMessage = {
      showModal: true,
      modalTitle: `STORICO: ${row.lastName} ${row.firstName}`,
      modalComponent: <HistoryView registry={row} />,
      onClose: () => props.setSelectedRegistry(),
    };
    setModalMessage(modalMessageConfirm);
  };

  const onHandleSetAtecoCode = (id: number, atecoCode: any) => {
    props.onSetAtecoCode(id, atecoCode);
  };

  const onHandleChamberView = (id: number) => {
    props.onGetChamberByCompany(id);
  };

  const onEscalation = (row: Company) => {
    props.onEscalation(row.id);
  }

  return (
    <Card className="validation-view-container">
      <div>
        <Modal modalMessage={modalMessage} />
      </div>
      <Spacer direction="vertical" size="m" />
      <div className="validation-table-container">
        {props.aml &&
        props.aml.data?.updatedCompanies &&
        props.aml.data?.updatedCompanies.length > 0 ? (
          <CompaniesTable
            showDeleteCompany={true}
            onDeleteCompany={onDeleteCompany}
            onHistoryClick={onHistoryClick}
            onHandleChamberView={(id: number) => onHandleChamberView(id)}
            onSetAtecoCode={(id: number, atecoCode: any) =>
              onHandleSetAtecoCode(id, atecoCode)
            }
            title="Verifica Aml"
            tableConfig={verificationConfig}
            companies={props.aml.data.updatedCompanies}
            chamberView={props.chamberViewSelected}
            onEscalation={(row: Company) => onEscalation(row)}
            escalationMailSuccess={props.sendEscalationSuccess}
            onDocumentSelected={(
              id: number,
              documentType: DOCUMENT_COMPANY_TYPE
            ) => props.onDocumentSelected(id, documentType)}
            documentLoading={props.documentLoading}
          />
        ) : (
          <Placeholder message="Nessuna azienda da validare" />
        )}
      </div>
    </Card>
  );
}

const mapStateToProps = (state: any) => {
  return {
    aml: state.aml,
    setDataSuccess: state.aml.setDataSuccess,
    sendEscalationSuccess: state.aml.sendEscalationSuccess,
    documentLoading: state.UI.documentLoading,
    chamberViewSelected: state.aml.chamberViewSelected,
    documentSelected: state.aml.documentSelected
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetCompanies: () => {
      dispatch(actions.getUpdatedCompanies());
    },
    onResetAmlState: () => {
      dispatch(actions.resetAmlState());
    },
    onGetHistory: (companyId: number, registry: CompanyMember) => {
      dispatch(actions.getHistory(companyId, registry));
    },
    setSelectedRegistry: (registry?: CompanyMember) => {
      dispatch(actions.setSelectedRegistry(registry));
    },
    onDelete: (companyId: number) => {
      dispatch(actions.deleteCompany(companyId));
    },
    onSetAtecoCode: (companyId: number, atecoCode: any) => {
      dispatch(actions.setAtecoCode(companyId, atecoCode));
    },
    onResetSetDataSuccess: () => {
      dispatch(actions.resetSetDataSuccess())
    },
    onGetChamberByCompany: (id: number) => {
      dispatch(actions.getChamberByCompany(id));
    },
    onEscalation: (companyId: number) => {
      dispatch(actions.sendEscalationEmail(companyId))
    },
    onDocumentSelected: (
      companyId: number,
      documentType: DOCUMENT_COMPANY_TYPE
    ) => {
      dispatch(actions.getDocumentCompany(companyId, documentType));
    },
    resetDocumentSelected: () => {
      dispatch(actions.resetDocumentSelected())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerificationView);
