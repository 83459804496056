import THEME from "../../core/theme/theme";

export const companiesTableStyle = {
  header: {
    style: {
      fontSize: 17,
      color: THEME.palette?.primary?.hover,
    },
  },
  headRow: {
    style: {
    },
  },
  rows: {
    style: {
    backgroundColor: THEME.palette?.grey,
    },
  },
  headCells: {
    style: {
      fontSize: 16,
      color: THEME.palette?.primary?.hover,
      fontWeight: 700,
      textTransform: "uppercase"
    },
  },
  cells: {
    style: {
        fontSize: 15,
    },
  },
  complianceModal: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    justifyContent: 'center',
    fontSize: '16px',
    color: THEME.palette?.green
  }
};

