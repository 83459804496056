import { CHART_TYPE } from "../../constants/ChartType";

export class ChartConfiguration {
    xAxes: AxesConfiguraton = {};
    yAxes: AxesConfiguraton = {};
    intervals?: string[] = [];
    datasets: DataSets[] = [];
    title?: string = '';
    tooltip?: TooltipConfiguration = {};
    type?: CHART_TYPE = CHART_TYPE.SINGLE_BAR;
    showCustomTooltip?:boolean = false;
    filters?: FiltersConfiguration = {};

  }

  export class ChartConfigurationBuilder {
    static createBarChart(type: CHART_TYPE, datasets: DataSets[], intervals: string[]) : ChartConfiguration {
      return {
        type: type,
        xAxes: {
          maxValue: 10,
          scales: {}
        },
        yAxes: {
          maxValue: 10,
          scales: {}
        },
        tooltip: {},
        filters: new FiltersConfiguration(),
        datasets : [...datasets],
        intervals: [...intervals]
      };
    }

    static createLineChart(datasets: LineDataSet[], intervals: string[]) {
      const c: ChartConfiguration = {
        type: CHART_TYPE.LINE,
        xAxes: {
          maxValue: 10,
          scales: {}
        },
        yAxes: {
          maxValue: 10,
          scales: {}
        },
        tooltip: {},
        filters: new FiltersConfiguration(),
        datasets : [...datasets],
        intervals: [...intervals]
      }
      return c;
    }
  }

  export class FiltersConfiguration {
    filterByNull?: boolean;
    filter?: Function;

    constructor() {
      this.filterByNull = true;
    }
  }

  export class TooltipConfiguration {
    title?: Function;
    label?: Function;
    labelTextColor?: Function;
  }

  export class AxesConfiguraton {
    maxValue?: any;
    scales?: ScalesConfiguration;
    label?: string = '';
    stepSize?: number;
    rotation?: number;
    truncateAt?: number;
    barPercentage?: number;
  }

  export class DataSets {
    data?: any[];
    label?: string;
    backgroundColor?: string;
    hoverBackgroundColor?: string;

    constructor(label: string = "", data: any[] = []) {
      this.data = data;
      this.label = label;
    }
  }

  export class LineDataSet extends DataSets {
    borderColor?: string;
    borderWidth? = 1;
    pointRadius? = 4;
    pointHoverRadius? = 5;
    lineTension? = 0;
    fill? = false;
  }

  export class ScalesConfiguration {
    fontColor?: string;
    callbackFunction?: Function;
  }

  export class CallbackParameters  {
    value?: any;
    index?: any;
    array? : any;

    constructor(value: any, index: any, array: any) {
      this.value = value;
      this.index = index;
      this.array = array;
    }
  }
