import { useState } from "react";
import "./AtecoCodePanel.scss";
import { useTranslation } from "react-i18next";
import { ATECO_CODE } from "../../core/constants/CompanyValues";
import { Button } from "@material-ui/core";
import Dropdown from "../Dropdown/Dropdown";
import Spacer from "../Spacer/Spacer";

export default function AtecoCodePanel(props: {
  id?: number;
  onSaveCode?: any;
}) {
  const { t } = useTranslation();

  const [selectedAtecoCode, setSelectedAtecoCode] = useState<any>();

  const options = ATECO_CODE(t).map((item) => {
    return {
      value: item.value,
      label: item.label,
    };
  });

  const onHandleSaveCode = () => {
   if (selectedAtecoCode) {
    props.onSaveCode(props.id, selectedAtecoCode.value)
   } 
  }

  return (
    <div className="ateco-code-panel">
      <Dropdown
        label="Seleziona un codice Ateco"
        options={options}
        onChange={(opt: any) => {
          setSelectedAtecoCode(opt);
        }}
      />
      <Spacer size="xl" direction="vertical"/>
      <div className="button-container">
        <Button
          color="primary"
          variant="contained"
          disableElevation
          disabled={!selectedAtecoCode || !selectedAtecoCode.value}
          onClick={onHandleSaveCode}
        >
          Salva Modifica
        </Button>
      </div>
    </div>
  );
}
