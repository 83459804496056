import { hideUiItem } from "./../UI/UI";
import amlInstance from "../../../../amlInstance";
import { API_URL_BUILDER } from "../../../constants/ApiUrl";
import { ITEM_UI } from "../../../constants/ItemUI";
import * as actionTypes from "../../../types/actionTypes";
import { CompanyStatusRequest } from "../../../types/ApiRequest/CompanyStatusRequest";
import { Company } from "../../../types/entity/Company";
import ToastMessage, { TOAST_TYPE } from "../../../types/UI/ToastMessage";
import { showUiItem } from "../UI/UI";
import {
  deleteCompanySuccess,
  getCompaniesSuccess,
  getCompanyMembersSuccess,
  getDocumentCompanySuccess,
  getHistorySuccess,
  getOldCompaniesSuccess,
  getUpdatedCompaniesSuccess,
  sendEscalationEmailSuccess,
  setDataSuccess,
  setChamberViewSuccess,
  setValidationSuccess,
} from "./success";
import { CompanyMember } from "../../../types/entity/CompanyMember";
import AtecoCodeRequest from "../../../types/ApiRequest/AtecoCodeRequest";
import { DOCUMENT_COMPANY_TYPE } from "../../../types/DocumentCompany";
import { setDocumentNode } from "../../../utilities/documentHandler";

export const getCompanies = (status: boolean) => {
  return (dispatch: any) => {
    amlInstance.post(API_URL_BUILDER.UPDATE_SCORE()).then(() => {
      amlInstance
        .get(API_URL_BUILDER.GET_COMPANIES(status))
        .then((res: any) => {
          if (res && res.data && res.data.status === "OK") {
            dispatch(getCompaniesSuccess(res.data.payload));
          }
        });
    });
  };
};

export const getNewCompanies = () => {
  return (dispatch: any) => {
    amlInstance.post(API_URL_BUILDER.UPDATE_SCORE()).then(() => {
      amlInstance.get(API_URL_BUILDER.GET_NEW_COMPANIES()).then((res: any) => {
        if (res && res.data && res.data.status === "OK") {
          dispatch(getCompaniesSuccess(res.data.payload));
        }
      });
    });
  };
};

export const getUpdatedCompanies = () => {
  return (dispatch: any) => {
    amlInstance
      .get(API_URL_BUILDER.GET_UPDATED_COMPANIES())
      .then((res: any) => {
        if (res && res.data && res.data.status === "OK") {
          dispatch(getUpdatedCompaniesSuccess(res.data.payload));
        }
      });
  };
};

export const getOldCompanies = () => {
  return (dispatch: any) => {
    amlInstance.get(API_URL_BUILDER.GET_OLD_COMPANIES()).then((res: any) => {
      if (res && res.data && res.data.status === "OK") {
        dispatch(getOldCompaniesSuccess(res.data.payload));
      }
    });
  };
};

export const deleteCompany = (companyId: number) => {
  return (dispatch: any) => {
    amlInstance
      .post(API_URL_BUILDER.DELETE_COMPANY(companyId))
      .then((res: any) => {
        if (res && res.data && res.data.status === "OK") {
          dispatch(deleteCompanySuccess(companyId));
        }
      });
  };
};

export const setSelectedRegistry = (registry: any) => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.SET_SELECTED_REGISTRY,
      payload: {
        registry,
      },
    });
  };
};

export const getHistory = (companyId: number, registry: CompanyMember) => {
  return (dispatch: any) => {
    if (registry && registry.id) {
      amlInstance.get(API_URL_BUILDER.GET_HISTORY(registry.id)).then((res) => {
        dispatch(
          getHistorySuccess(companyId, registry, res.data.payload.history)
        );
        dispatch(setSelectedRegistry(registry));
      });
    }
  };
};

export const sendEscalationEmail = (companyId: number) => {
  return (dispatch: any) => {
    amlInstance.get(API_URL_BUILDER.ESCALATION_EMAIL(companyId)).then((res) => {
      if (res && res.data && res.data.status === "OK")
        dispatch(sendEscalationEmailSuccess(true));
    });
  };
};

export const getUsersByCompanyId = (companyId: number) => {
  return (dispatch: any) => {
    amlInstance
      .get(API_URL_BUILDER.GET_USERS_BY_COMPANY_ID(companyId))
      .then((res: any) => {
        if (res && res.data && res.data.status === "OK") {
          dispatch(getCompanyMembersSuccess(res.data.payload));
        }
      });
  };
};

export const setCompanyStatus = (companyIds: number[], approved: boolean) => {
  const bodyRequest: CompanyStatusRequest = {
    companyIds,
    approved,
  };
  return (dispatch: any) => {
    amlInstance
      .post(API_URL_BUILDER.SET_COMPANY_STATUS(), bodyRequest)
      .then((res: any) => {
        if (res && res.data && res.data.status === "OK") {
          let toastMessage: ToastMessage = {
            message: "Operazione completata con successo",
            autoCloseTime: 1500,
            showClose: true,
            type: TOAST_TYPE.SUCCESS,
          };
          dispatch(setValidationSuccess());
          dispatch(showUiItem(ITEM_UI.TOAST_MESSAGE, toastMessage));
        }
      });
  };
};

export const setAtecoCode = (companyId: number, atecoCode: string) => {
  return (dispatch: any) => {
    const bodyRequest: AtecoCodeRequest = {
      companyId,
      atecoCode,
    };
    amlInstance
      .post(API_URL_BUILDER.SET_ATECO_CODE(), bodyRequest)
      .then((res: any) => {
        if (res && res.data && res.data.status === "OK") {
          let toastMessage: ToastMessage = {
            message: "Operazione completata con successo",
            autoCloseTime: 1500,
            showClose: true,
            type: TOAST_TYPE.SUCCESS,
          };
          dispatch(setDataSuccess(true));
          dispatch(showUiItem(ITEM_UI.TOAST_MESSAGE, toastMessage));
        }
      });
  };
};

export const getChamberByCompany = (companyId: number) => {
  return (dispatch: any) => {
    amlInstance
      .get(API_URL_BUILDER.GET_CHAMBER_BY_COMPANY(companyId))
      .then((res: any) => {
        if (res && res.data && res.data.status === "OK") {
          dispatch(setChamberViewSuccess(res.data.payload.documents[0]));
        }
      });
  };
};

export const getDocumentCompany = (
  companyId: number,
  documentType: DOCUMENT_COMPANY_TYPE
) => {
  return (dispatch: any) => {
    dispatch(showUiItem(ITEM_UI.DOCUMENT_LOADING));
    amlInstance
      .get(API_URL_BUILDER.GET_COMPANY_DOCUMENT(companyId, documentType))
      .then((res: any) => {
        if (res && res.data && res.data.status === "OK") {
          let documentNode = setDocumentNode(documentType);
          if (documentNode && documentNode !== "") {
            let document = res.data[documentNode];
            document.mimeType = res.data.mimeType;
            dispatch(getDocumentCompanySuccess(document));
          }
          if (res.data[documentNode]) {
            dispatch(hideUiItem(ITEM_UI.DOCUMENT_LOADING));
          }
        } else {
          dispatch(hideUiItem(ITEM_UI.DOCUMENT_LOADING));
        }
      })
      .catch(() => dispatch(hideUiItem(ITEM_UI.DOCUMENT_LOADING)));
  };
};

export const addCompany = (company: Company) => {
  return {
    type: actionTypes.ADD_COMPANY,
    company,
  };
};

export const removeCompany = (companyId: number) => {
  return {
    type: actionTypes.REMOVE_COMPANY,
    companyId,
  };
};
