import { DOCUMENT_COMPANY_TYPE } from "../types/DocumentCompany"

export enum API_URL {
    DESK = 'aml/desk/v1/',
    AML = 'aml/',
}

export const API_URL_BUILDER= {
    GET_DASHBOARD_DATA: () => {
        return `${API_URL.DESK}getDashBoardData`
    },
    GET_HISTORY: (registryId: number) => {
        return `${API_URL.AML}getHistory?idRegistry=${registryId}`
    },
    GET_OLD_COMPANIES: () => {
        return `${API_URL.DESK}getInactiveCompanies`
    },
    DELETE_COMPANY: (companyId: number) => {
        return `${API_URL.DESK}endContract?companyId=${companyId}`
    },
    UPDATE_SCORE: () => {
        return `${API_URL.AML}updateAranScores`
    },
    GET_COMPANIES: (status: boolean | undefined) => {
        return `${API_URL.AML}getCompanies?withStatus=${status}`
    },
    GET_NEW_COMPANIES: () => {
        return `${API_URL.DESK}getNewCompanies`
    },
    GET_USERS_BY_COMPANY_ID: (companyId: number) => {
        return `${API_URL.AML}getUsersByCompany?companyId=${companyId}`
    },
    SET_COMPANY_STATUS: () => {
        return `${API_URL.AML}setCompanyStatus`
    },
    GET_UPDATED_COMPANIES: () => {
        return `${API_URL.AML}getUpdatedCompanies?updated=true`
    },
    SET_ATECO_CODE: () => {
        return `${API_URL.DESK}setAtecoCode`
    },
    GET_CHAMBER_BY_COMPANY: (companyId: number) => {
        return `${API_URL.DESK}getChamberByCompany?companyId=${companyId}`
    },
    GET_COMPANY_DOCUMENT: (companyId: number, documentType: DOCUMENT_COMPANY_TYPE ) => {
        return `${API_URL.DESK}getCompanyDocument?companyId=${companyId}&type=${documentType}`
    },
    ESCALATION_EMAIL : (companyId: number) => {
        return `${API_URL.DESK}compliance-email?companyId=${companyId}`
    }
}
