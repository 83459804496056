export enum ROUTES {
    INITIAL_ROUTE = "/",
    LOGIN = "/login",
    DASHBOARD = "/dashboard",
    OKTA_CALLBACK = "/okta/login/callback",
    OKTA_RESUME = "/okta",
    AML_VALIDATION = '/aml-validation',
    COMPANIES = '/companies',
    OLD_CLIENTS = '/clients',
    VERIFICATION = '/aml-verification'
  }
