export default class ToastMessage {
 message?: string;
 icon?: any;
 autoCloseTime?: number;
 showClose?: boolean;
 type?: TOAST_TYPE;

  constructor(
    message: string,
    icon: any,
    autoCloseTime: number = 5000,
    showClose: boolean = true,
    type: TOAST_TYPE = TOAST_TYPE.ERROR
  ) {
    this.message = message;
    this.icon = icon;
    this.autoCloseTime = autoCloseTime;
    this.showClose = showClose;
    this.type = type;
  }
}

export enum TOAST_TYPE {
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  ERROR = "ERROR",
}