import { AmlState } from "../../types/state/AmlState";
import * as actionTypes from "../../types/actionTypes";
import { Company } from "../../types/entity/Company";

export const initialAmlState: AmlState = new AmlState();

const amlReducer = (state: AmlState = initialAmlState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_DASHBOARD_SUCCESS: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          newCompaniesCount: action.dashboard.newCompaniesCount,
          updatedCompaniesCount: action.dashboard.updatedCompaniesCount,
          companyPerMonths: action.dashboard.companyPerMonths
        },
      };
    }
    case actionTypes.SET_COMPANIES_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          companies: action.companies,
        },
      };
    }
    case actionTypes.SET_UPDATED_COMPANIES_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          updatedCompanies: action.companies,
        },
      };
    }
    case actionTypes.SET_OLD_COMPANIES_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          oldCompanies: action.companies,
        },
      };
    }
    case actionTypes.DELETE_COMPANY_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          companies: state.data.companies.filter((item: Company) => item.id !== action.companyId),
        },
      };
    }
    case actionTypes.SET_DOCUMENT_COMPANY_SUCCESS: {

      return {
        ...state,
        documentSelected: {...action.document}
      };
    }

    case actionTypes.SET_CHAMBER_VIEW_SUCCESS: {
      return {
        ...state,
        chamberViewSelected: {...action.document}
      };
    }

    case actionTypes.ADD_COMPANY: {
      let companies: Company[] = [...state.companiesSelected];
      let companiesIds: string[] = [...state.companiesIdsSelected];
      companies.push(action.company);
      companiesIds.push(action.company.id);
      return {
        ...state,
        companiesIdsSelected: companiesIds,
        companiesSelected: companies,
      };
    }

    case actionTypes.REMOVE_COMPANY: {
      let companiesFiltered = state.companiesSelected.filter(
        (el) => el.id !== action.companyId
      );
      let companiesIdsFiltered = state.companiesIdsSelected.filter(
        (el) => el !== action.companyId
      );
      return {
        ...state,
        companiesIdsSelected: companiesIdsFiltered,
        companiesSelected: companiesFiltered,
      };
    }

    case actionTypes.SET_COMPANY_MEMBER_SUCCESS: {
      let companiesUpdated = state.data?.companies ? state.data?.companies : [];
      let index: number = companiesUpdated.findIndex(
        (el) => el.id === action.index
      );
      companiesUpdated[index].members = action.members;
      return {
        ...state,
        data: {
          ...state.data,
          companies: action.companies,
        },
      };
    }



    case actionTypes.SET_HISTORY_SUCCESS : {
      const companyIndex: number = state.data.companies.findIndex(el => el.id === action.payload.companyId);
      let companies = state.data.companies;
      if (companyIndex > -1) {
        companies[companyIndex].members = state.data.companies[companyIndex].members.map(item => {
          item.history = item.id === action.payload.registry.id ? action.payload.history : null;
          return item;
        })

      }

      return {
        ...state,
        data: {
          ...state.data,
          companies: companies
        }
      }
    }
    case actionTypes.SET_SELECTED_REGISTRY: {
      return {
        ...state,
        selectedRegistry: action.payload.registry
      }
    }

    case actionTypes.SEND_ESCALATION_SUCCESS: {
      return {
        ...state,
        sendEscalationSuccess: action.escalationEmailStatus
      }
    }

    case actionTypes.SET_VALIDATION_SUCCESS: {
      return {
        ...state,
        validateStatusSuccess: true,
      };
    }

    case actionTypes.SET_DATA_SUCCESS: {
      return {
        ...state,
        setDataSuccess: action.status,
      };
    }

    case actionTypes.RESET_DOCUMENT_SELECTED: {
      return {
        ...state,
        documentSelected: null
      }
    }

    case actionTypes.RESET_AML_STATE: {
      return {
        ...initialAmlState,
      };
    }

    default:
      return state;
  }
};

export default amlReducer;
