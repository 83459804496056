import THEME from "../../core/theme/theme";
import { Trans } from "react-i18next";
import { Company } from "../../core/types/entity/Company";
import { TableConfig } from "../../core/types/UI/TableConfig";
import {
  ATECO_CODE,
  COMPANY_CONSTITUTION_DATE,
  COMPANY_LEGAL_STATUS,
  FOUND_SOURCES,
} from "../../core/constants/CompanyValues";
import {
  getBooleanValue,
  getIconStatus,
  getSelectedValue,
  setTooltipRiskColor,
  setTooltipValueColor,
} from "../../core/utilities/tableHelpers";
import { Button } from "@material-ui/core";
import CompanyInfoTooltip from './../CompanyInfoTooltip/CompanyInfoTooltip';
import TooltipColor from "../Tooltip/TooltipColor/TooltipColor";
import CellTable from "../CellTable/CellTable";
import Spacer from "../Spacer/Spacer";
import Tag from "../Tag/Tag";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import DescriptionIcon from "@material-ui/icons/Description";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import TimerIcon from "@material-ui/icons/Timer";
import EditIcon from "@material-ui/icons/Edit";

const requestExtraDoc = (company: Company) => {
  const emailAddress = company.members
    .filter((member) => member.executor === true)
    .map((item) => {
      return item.email;
    });

  const emailSubject =
    "Iscrizione Primaton: Richiesta Documentazione Aggiuntiva";
  const emailBody = "Email Test";

  //Send Email request
  window.location.href = `mailto:${emailAddress}?subject=${emailSubject}&body=${emailBody}`;
};

export const companiesColumns = (
  props: {
    onCompanySelected?: any;
    onApproval?: any;
    onReject?: any;
    onDeleteCompany?: any;
    showDeleteCompany?: boolean;
    onEscalation?: any;
  },
  tableConfig?: TableConfig | undefined,
  t?: any,
  onHandleAtecoCode?: any,
  onHandleChamberView?: any
) => {
  const onHandleEditAtecoCode = (id: number | undefined) => {
    onHandleAtecoCode(id);
  };

  const onHandleClickDocument = (row: Company) => {
    onHandleChamberView(row);
  };

  return [
    {
      name: t("COMPANIES_TABLE.HEAD.VAT_NUMBER"),
      selector: "companyVat",
      minWidth: "180px",
      sortable: true,
      cell: (row: Company) => <CellTable data={row.vatNumber} />,
    },
    {
      name: t("COMPANIES_TABLE.HEAD.BUSINESS_NAME"),
      selector: "companyName",
      minWidth: "170px",
      sortable: true,

      cell: (row: Company) => <CellTable data={row.companyName} />,
    },
    {
      name: t("COMPANIES_TABLE.HEAD.MAX_SCORE"),
      selector: "maxScore",
      sortable: true,

      cell: (row: Company) =>
        row.maxScore || row.maxScore === 0 ? (
          <Tag tagValue={row.maxScore} />
        ) : (
          <TimerIcon htmlColor={THEME.palette?.primary?.hover} />
        ),
    },
    {
      name: t("COMPANIES_TABLE.HEAD.LEGAL_STATUS"),
      selector: "legalStatus",
      sortable: true,

      cell: (row: Company) => {
        const selectedStatus = getSelectedValue(
          COMPANY_LEGAL_STATUS(t),
          row.legalStatus
        );
        return selectedStatus ? (
          <TooltipColor
            title={selectedStatus.label}
            color={setTooltipRiskColor(selectedStatus.risk)}
          />
        ) : (
          <></>
        );
      },
    },
    {
      name: t("COMPANIES_TABLE.HEAD.CONSTITUTION_DATE"),
      selector: "constitutionDate",
      sortable: true,

      cell: (row: Company) => {
        const selectedStatus = getSelectedValue(
          COMPANY_CONSTITUTION_DATE(t),
          row.constitutionDate
        );
        return selectedStatus ? (
          <TooltipColor
            title={selectedStatus.label}
            color={setTooltipRiskColor(selectedStatus.risk)}
          />
        ) : (
          <></>
        );
      },
    },
    {
      name: t("COMPANIES_TABLE.HEAD.FUNDS_SOURCE"),
      selector: "fundsSource",
      sortable: true,

      cell: (row: Company) => {
        const selectedStatus = getSelectedValue(
          FOUND_SOURCES(t),
          row.fundsSource
        );
        return selectedStatus ? (
          <TooltipColor
            title={selectedStatus.label}
            color={setTooltipRiskColor(selectedStatus.risk)}
          />
        ) : (
          <></>
        );
      },
    },
    {
      name: t("COMPANIES_TABLE.HEAD.PEP"),
      selector: "pep",
      sortable: true,

      cell: (row: Company) => (
        <TooltipColor
          title={getBooleanValue(row.pep, t)}
          color={setTooltipValueColor(row.pep)}
        />
      ),
    },
    {
      name: t("COMPANIES_TABLE.HEAD.PIS"),
      selector: "pis",
      sortable: true,

      cell: (row: Company) => (
        <TooltipColor
          title={getBooleanValue(row.pis, t)}
          color={setTooltipValueColor(row.pis)}
        />
      ),
    },
    {
      name: t("COMPANIES_TABLE.HEAD.ATECO"),
      selector: "atecoCode",
      sortable: true,

      cell: (row: Company) => {
        const selectedAtecoCode: any = row.atecoCode
          ? getSelectedValue(ATECO_CODE(t), row.atecoCode)
          : null;
        return row.atecoCode && selectedAtecoCode ? (
          <TooltipColor
            title={selectedAtecoCode.label}
            color={setTooltipRiskColor(selectedAtecoCode.risk)}
          />
        ) : (
          <div onClick={() => onHandleEditAtecoCode(row.id)}>
            <EditIcon
              className="clickable-icon"
              htmlColor={THEME.palette?.primary?.hover}
            />
          </div>
        );
      },
    },
    {
      name: t("COMPANIES_TABLE.HEAD.COMPANY_DOCUMENTS"),
      selector: "chamberView",
      minWidth: "120px",
      cell: (row: Company) => (
        <DescriptionIcon
          onClick={() => onHandleClickDocument(row)}
          htmlColor={THEME.palette?.primary?.hover}
          style={{ cursor: "pointer" }}
          titleAccess={t("COMPANIES_TABLE.HEAD.CHAMBER_VIEW_ALT")}
        />
      ),
    },
    {
      name: "",
      omit: !(tableConfig && tableConfig.showExtraDoc === true),

      cell: (row: Company) => (
        <MailOutlineIcon
          onClick={() => requestExtraDoc(row)}
          htmlColor={THEME.palette?.primary?.hover}
          style={{ cursor: "pointer" }}
          titleAccess={t("COMPANIES_TABLE.HEAD.EXTRA_DOC_ALT")}
        />
      ),
    },
    {
      name: "",
      omit: tableConfig && !tableConfig?.isValidation && !tableConfig?.isVerify,
      cell: (row: Company) => (
        <ClearAllIcon
          onClick={() => props.onEscalation(row)}
          htmlColor={THEME.palette?.primary?.hover}
          style={{ cursor: "pointer" }}
          titleAccess={t("COMPANIES_TABLE.HEAD.ESCALATION")}
          fontSize="large"
        />
      ),
    },
    {
      name: "",
      cell: (row: Company) => (
        <CompanyInfoTooltip company={row}/>
      ),
    },
    {
      name: "",
      selector: "selector",
      minWidth: tableConfig && tableConfig.isValidation === true ? "300px" : "",
      omit: !(tableConfig && tableConfig.isValidation === true),

      cell: (row: Company) => (
        <div className="validation-actions-container">
          <Button
            className="action-button reject"
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => props.onReject(row)}
          >
            <Trans>COMPANIES_TABLE.REJECT</Trans>
          </Button>
          <Spacer size="m" />
          <Button
            color="primary"
            variant="contained"
            disableElevation
            size="small"
            className="action-button"
            onClick={() => props.onApproval(row)}
          >
            <Trans>COMPANIES_TABLE.APPROVE</Trans>
          </Button>
          <Spacer size="m" />
        </div>
      ),
    },
    {
      name: t("COMPANIES_TABLE.HEAD.STATUS"),
      selector: "selector",
      omit: !(tableConfig && tableConfig.showStatus === true),

      cell: (row: Company) => getIconStatus(row.approved),
    },
    {
      name: "",
      selector: "delete",
      cell: (row: Company) =>
        props.showDeleteCompany && (
          <DeleteOutlineIcon
            onClick={() => props.onDeleteCompany(row)}
            htmlColor={THEME.palette?.primary?.hover}
            style={{ cursor: "pointer" }}
            titleAccess={t("COMPANIES_TABLE.HEAD.CLOSE_CONTRACT")}
          />
        ),
    },
  ];
};
