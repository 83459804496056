import { Card } from "@material-ui/core";
import { useHistory } from "react-router";
import "./DashboardCard.scss";

export default function DashboardCard(props: {
  title?: string;
  subtitle?: any;
  icon?: any;
  routeCard?: any;
  children?: any;
}) {
  const history = useHistory();

  const goTo = () => {
    if (props.routeCard) {
      history.push(props.routeCard);
    }
  };

  return (
    <Card className={`dashboard-card ${props.routeCard ? 'clickable' : ''}`} elevation={4} onClick={goTo}>
      <div className="dashboard-header-card">
        <div className="dashboard-card-title-container">
          <span className="card-title">{props.title}</span>
          {props.subtitle && (
            <span className="card-subtitle">{props.subtitle}</span>
          )}
        </div>
        <span className="card-icon">{props.icon}</span>
      </div>
      <div className="dashboard-card-content">{props.children}</div>
    </Card>
  );
}
