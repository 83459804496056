import {TableConfig} from "../types/UI/TableConfig";

export  const validationConfig: TableConfig = {
    showExtraDoc: true,
    showStatus: false,
    showHistory: false,
    isValidation: true,
    isVerify: false,
}

export const verificationConfig: TableConfig = {
    isValidation: false, 
    isVerify: true,
    showHistory: true,
    showExtraDoc: true, 
    showStatus : false 
}

export  const companiesConfiguration: TableConfig = {
    showExtraDoc: false,
    showStatus: true,
    showHistory: true,
    isValidation: false,
    isVerify: false
}

export  const oldClientsConfiguration: TableConfig = {
    showExtraDoc: false,
    showStatus: false,
    showHistory: true,
    isValidation: false,
    isVerify: false
}


