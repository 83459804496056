import THEME from "../../core/theme/theme";
import { CompanyMember } from "../../core/types/entity/CompanyMember";

import HistoryIcon from "@material-ui/icons/History";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CellTable from "../CellTable/CellTable";
import Tag from "../Tag/Tag";
import { TableConfig } from "../../core/types/UI/TableConfig";
import CellTableRoles from "../CellTableRoles/CellTableRoles";

export const membersColumns = (
  onHistoryClick: any,
  tableConfig: TableConfig | undefined,
  t?: any

) => [
  {
    name: "Ruoli",
    selector: "id",
    width: "100px",
    sortable: true,
    cell: (row: CompanyMember) => <CellTableRoles member={row}/>,
  },
  {
    name: t("MEMBERS_TABLE.HEAD.FISCAL_CODE"),
    selector: "fiscalCode",
    sortable: true,

    cell: (row: CompanyMember) => <CellTable data={row.fiscalCode} />,
  },
  {
    name: t("MEMBERS_TABLE.HEAD.DENOMINATION"),
    selector: "denomination",
    sortable: true,

    cell: (row: CompanyMember) => (
      <CellTable data={row.lastName + " " + row.firstName} />
    ),
  },
  {
    name: t("MEMBERS_TABLE.HEAD.LOCALITY"),
    selector: "city",
    sortable: true,

    cell: (row: CompanyMember) => <CellTable data={row.city} />,
  },
  {
    name: t("MEMBERS_TABLE.HEAD.SCORE"),
    selector: "score",
    sortable: true,
    omit: tableConfig && tableConfig.isVerify !== true ? false : true,

    cell: (row: CompanyMember) =>
      row.score || row.score === 0 ? (
        <Tag tagValue={row.score} />
      ) : (
        <HourglassEmptyIcon htmlColor={THEME.palette?.primary?.hover} />
      ),
  },
  {
    name: t("MEMBERS_TABLE.HEAD.OLD_SCORE"),
    selector: "oldScore",
    sortable: true,
    omit: tableConfig && tableConfig.isVerify === true ? false : true,

    cell: (row: CompanyMember) =>
      row.oldScore || row.oldScore === 0 ? (
        <Tag tagValue={row.oldScore} />
      ) : (
        <HourglassEmptyIcon htmlColor={THEME.palette?.primary?.hover} />
      ),
  },
  {
    name: t("MEMBERS_TABLE.HEAD.NEW_SCORE"),
    selector: "score",
    sortable: true,
    omit: tableConfig && tableConfig.isVerify === true ? false : true,

    cell: (row: CompanyMember) =>
      row.score || row.score === 0 ? (
        <Tag tagValue={row.score} />
      ) : (
        <HourglassEmptyIcon htmlColor={THEME.palette?.primary?.hover} />
      ),
  },
  {
    name: "",
    selector: "history",
    omit: tableConfig && tableConfig.showHistory === true ? false : true,

    cell: (row: CompanyMember) => (
      <HistoryIcon
        className="clickable-icon"
        onClick={() => onHistoryClick(row)}
        htmlColor={THEME.palette?.primary?.hover}
      />
    ),
  },
  {
    name: "",
    selector: "",

    cell: () => <span />,
  },
];
