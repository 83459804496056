
import { SelectOption } from "../../core/types/UI/SelectOption";
import Select from "react-select";
import "./Dropdown.scss";

export default function Dropdown(props: {
  label?: string;
  options?: SelectOption[];
  required?: boolean;
  value?: any;
  defaultValue?: any;
  onChange?: any
}) {
  return (
    <div className="dropdown-container">
      {props.label && (
        <span className="dropdown-label">{props.label} </span>
      )}
      <Select
        label="Seleziona un codice Ateco"
        options={props.options}
        menuPortalTarget={document.body}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        value={props.value}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
    </div>
  );
}
