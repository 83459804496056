import "./CompaniesList.scss";
import { Company } from "../../core/types/entity/Company";
import Spacer from "../Spacer/Spacer";

export default function CompaniesList(props: {
  companiesList: Company[];
  companiesListClassName?: string;
  companyItemClassName?: string;
}) {
  return (
    <div
      className={`companies-list-container ${
        props.companiesListClassName ? props.companiesListClassName : ""
      }`}
    >
      {props.companiesList.map((company) => (
        <>
        <span
          className={`company-item ${
            props.companyItemClassName ? props.companyItemClassName : ""
          }`}
        >
          {company.businessName}
        </span>
        <Spacer direction="vertical"/>
        </>
      ))}
    </div>
  );
}
