import THEME from "../../core/theme/theme";

export const membersTableStyle = {
  header: {
    style: {
      fontSize: 15,
      color: THEME.palette?.primary?.hover,
    },
  },
  headRow: {
    style: {
 
    },
  },
  rows: {
    style: {

    },
  },
  headCells: {
    style: {
      fontSize: 14,
      color: THEME.palette?.primary?.main,
      fontWeight: 700,
    },
  },
  cells: {
    style: {
        fontSize: 13
    },
  },
};
