import { useEffect } from "react";
import "./DashboardView.scss";
import { connect } from "react-redux";
import * as actions from "../../core/store/actions";
import { ROUTES } from "../../core/constants/Routes";

import BarChartIcon from "@material-ui/icons/BarChart";
import PlaylistAddCheck from "@material-ui/icons/PlaylistAddCheck";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import Card from "@material-ui/core/Card";

import DashboardCard from "../../components/DashboardCard/DashboardCard";
import Title from "../../components/Title/Title";
import Spacer from "../../components/Spacer/Spacer";
import DashboardData from "../../core/types/entity/DashboardData";
import ClientsForMonthsChart from "../../components/ClientsForMonthsChart/ClientsForMonthsChart";

function DashboardView(props: {
  dashboard?: DashboardData;
  onGetDashboardData?: any;
}) {
  useEffect(() => {
    props.onGetDashboardData();
  }, []);

  return (
    <Card className="dashboard-view-container">
      <Title title="Dashboard" />
      <Spacer direction="vertical" size="xl" />
      {props.dashboard && (
        <div className="dashboard-view-content">
          <div className="dashboard-row">
            <DashboardCard
              title="Nuovi Clienti Iscritti"
              subtitle={props.dashboard?.newCompaniesCount}
              icon={<PlaylistAddCheck fontSize="large" />}
              routeCard={ROUTES.AML_VALIDATION}
            />
            <Spacer size="xxl" />
            <DashboardCard
              title="Clienti da Verificare"
              subtitle={props.dashboard?.updatedCompaniesCount}
              icon={<AlarmOnIcon fontSize="large" />}
              routeCard={ROUTES.VERIFICATION}
            />
          </div>
          <Spacer direction="vertical" size="xxl" />
          <div className="dashboard-row">
            <DashboardCard
              title="Clienti Iscritti/Mesi"
              icon={<BarChartIcon fontSize="large" />}
            >
              <ClientsForMonthsChart datasource={props.dashboard.companyPerMonths}/>
            </DashboardCard>
          </div>
          <Spacer direction="vertical" size="xl" />
        </div>
      )}
    </Card>
  );
}

const mapStateToProps = (state: any) => {
  return {
    dashboard: state.aml.dashboard,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetDashboardData: () => {
      dispatch(actions.getDashboardData());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);
