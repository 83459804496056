import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export default function TooltipIcon(props: {
  title: any;
  icon: any;
}) {
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      color: 'white',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(10),
      border: '1px solid #dadde9',
    },
  }));
  
  return (
    <HtmlTooltip placement="top-start" title={props.title} arrow>
      {props.icon}
    </HtmlTooltip>
  );
}
