import amlInstance from "../../../../amlInstance";
import { API_URL_BUILDER } from "../../../constants/ApiUrl";
import { getDashboardDataSuccess } from "./success";

export const getDashboardData = () => {
    
    return (dispatch: any) => {
        amlInstance
            .get(API_URL_BUILDER.GET_DASHBOARD_DATA())
            .then((res: any) => {
              if (res && res.data && res.data.status === "OK") {
                dispatch(getDashboardDataSuccess(res.data.payload));
              }
            });
    };
  };