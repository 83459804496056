import {
  AppBar,
  Toolbar,
} from "@material-ui/core";
import React from 'react';
import { NavLink } from "react-router-dom";
import LogoPrimaton from '../../assets/images/logo-primaton.svg';
import { ROUTES } from "../../core/constants/Routes";

export default function Header(props: { children?: any; headerClass: any }) {
  return (
      <AppBar position="fixed" color={'inherit'} className={props.headerClass} >
        <Toolbar className="toolbar">
          {props.children}
          <NavLink to={ROUTES.DASHBOARD}>
          <img src={LogoPrimaton} height="30px" alt="logo" />
          </NavLink>
        </Toolbar>
      </AppBar>

  );
}

