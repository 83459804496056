import {Route, Switch, Redirect, useHistory} from "react-router-dom";
import { ROUTES } from "../core/constants/Routes";
import DashboardView from "../views/DashBoard/DashboardView";
import OktaLoginView from "../views/OktaLogin/OktaLoginView";
import OktaResumeView from "../views/OktaResume/OktaResumeView";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import { oktaAuth } from "../okta";
import ValidationView from '../views/Validation/ValidationView';
import CompaniesView from '../views/Companies/CompaniesView';
import OldClientsView from '../views/OldClients/OldClientsView';
import VerificationView from '../views/Verification/VerificationView';

export default function Routes() {
  const history = useHistory();
  const onAuthRequired = () => {
      history.push('/login');
  }

    return (
      <>
      <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired} >
          <Switch>
          <Route
              exact
              path={ ROUTES.INITIAL_ROUTE}
            >
              <Redirect to={ROUTES.DASHBOARD} />
            </Route>
            <Route
              path={ROUTES.OKTA_CALLBACK}
              component={LoginCallback}
            />

            <SecureRoute
              exact
              path={ROUTES.OKTA_RESUME}
              component={OktaResumeView}
            />

            <Route
              exact
              path={ROUTES.LOGIN}
              component={OktaLoginView}
            />
            <SecureRoute
              exact
              path={ROUTES.DASHBOARD}
              component={DashboardView}
            />
            <SecureRoute
                exact
                path={ROUTES.AML_VALIDATION}
                component={ValidationView}
            />
            <SecureRoute
                exact
                path={ROUTES.COMPANIES}
                component={CompaniesView}
            />
            <SecureRoute
                exact
                path={ROUTES.OLD_CLIENTS}
                component={OldClientsView}
            />
            <SecureRoute
                exact
                path={ROUTES.VERIFICATION}
                component={VerificationView}
            />
          </Switch>
      </Security>
      </>
    )
}
