import { ErrorItem } from "./ErrorItem";

export default class ErrorMessage {
    code: string;
    description: string;
    visible?: boolean;
    autoClose?: boolean = true;

    constructor(code: string, description: string, autoClose: boolean = true) {
      this.code = code;
      this.description = description;
      this.autoClose = autoClose;
      this.visible = true;
    }
  }

  export const mapErrorMessage = (payload: any, autoClose: boolean = true) => {
    const errors = payload.errors;
    if (errors && errors.length > 0) {
      return errors.map(
        (error: any) => new ErrorMessage(error.code, error.description, autoClose)
      );
    }

    return [];
  };

  export const mapErrorItemToErrorMessage = (errors: ErrorItem[]) => {
    return errors.map(
        (error) => {
            return error.list.map(
                (item) => new ErrorMessage (
                    error.key,
                    item,
                    true
                )
            )
        }
    )
  }
