import "./PdfViewer.scss";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { Fragment } from "react";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import { printPlugin, RenderPrintProps } from "@react-pdf-viewer/print";
import {
  RenderCurrentScaleProps,
  RenderZoomInProps,
  RenderZoomOutProps,
  zoomPlugin,
} from "@react-pdf-viewer/zoom";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import { toolbarPlugin, ToolbarSlot } from "@react-pdf-viewer/toolbar";
import {
  fullScreenPlugin,
  RenderEnterFullScreenProps,
} from "@react-pdf-viewer/full-screen";

import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import PrintIcon from "@material-ui/icons/Print";
import Spacer from "../Spacer/Spacer";
import THEME from "../../core/theme/theme";

export default function PdfViewer(props: {
  pdf?: any;
  fileName?: string;
  type?: any;
}) {

  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: () => {
      // `file.name` is the URL of opened file
      return props.fileName?  props.fileName : 'download';
    },
  });
  const printPluginInstance = printPlugin();
  const zoomPluginInstance = zoomPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();
  const { EnterFullScreen } = fullScreenPluginInstance;
  const thumbnailPluginInstance = thumbnailPlugin();
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;
  const {DownloadButton} = getFilePluginInstance;

  const toolbarHeaderPlugins = [
    fullScreenPluginInstance,
    getFilePluginInstance,
    printPluginInstance,
    zoomPluginInstance,
    thumbnailPluginInstance,
    toolbarPluginInstance,
  ];

  return (
    <div className="pdf-viewer-container">
      <Spacer direction="vertical" size="m" />
      <Toolbar>
        {(props: ToolbarSlot) => {
          const {
            CurrentPageInput,
            CurrentScale,
            NumberOfPages,
            ZoomIn,
            ZoomOut,
            Print
          } = props;
          return (
            <Fragment>
              <div className="pdf-header">

                <Spacer direction="horizontal" dynamic />
                <div className="pdf-page-count">
                  <CurrentPageInput /> / <NumberOfPages />
                </div>

                <Spacer direction="horizontal" size="xl" />

                <ZoomOut>
                  {(props: RenderZoomOutProps) => (
                    <ZoomOutIcon
                      onClick={props.onClick}
                      htmlColor={THEME.palette?.primary?.hover}
                    />
                  )}
                </ZoomOut>

                <Spacer direction="horizontal" size="xl" />

                <ZoomIn>
                  {(props: RenderZoomInProps) => (
                    <ZoomInIcon
                      onClick={props.onClick}
                      htmlColor={THEME.palette?.primary?.hover}
                    />
                  )}
                </ZoomIn>

                <Spacer direction="horizontal" size="xl" />

                <EnterFullScreen>
                  {(props: RenderEnterFullScreenProps) => (
                    <FullscreenIcon
                      onClick={props.onClick}
                      htmlColor={THEME.palette?.primary?.hover}
                    />
                  )}
                </EnterFullScreen>

                <Spacer direction="horizontal" size="xl" />

                <CurrentScale>
                  {(props: RenderCurrentScaleProps) => (
                    <span>{`${Math.round(props.scale * 100)}%`}</span>
                  )}
                </CurrentScale>

                <Spacer direction="horizontal" size="xl" />

                <DownloadButton />     

                <Spacer direction="horizontal" size="xl" />

                <Print>
                  {(props: RenderPrintProps) => (
                    <PrintIcon
                      onClick={props.onClick}
                      htmlColor={THEME.palette?.primary?.hover}
                    />
                  )}
                </Print>
              </div>
            </Fragment>
          );
        }}
      </Toolbar>
      <Viewer
        fileUrl={props.pdf}
        defaultScale={SpecialZoomLevel.PageFit}
        plugins={toolbarHeaderPlugins}
      />
    </div>
  );
}
