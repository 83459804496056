import Spacer from "../../components/Spacer/Spacer";
import Placeholder from "../../components/Placeholder/Placeholder";
import {CompanyMember} from '../../core/types/entity/CompanyMember';
import HistoryTable from '../../components/HistoryTable/HistoryTable';
import './HistoryView.scss';

const HistoryView = (props: { registry: CompanyMember; onGetHistory?: any }) => {


    return (
    <div className="history-view-container">
        <Spacer direction="vertical" size="m" />
        <div className="history-table-container">
            {props.registry &&
            props.registry.history &&
            props.registry.history.length > 0 ? (
                <HistoryTable dataSource={props.registry.history} />
            ) : (
                <Placeholder message="Nessun dato storico" />
            )}
        </div>
    </div>
    );
}



export default HistoryView;
