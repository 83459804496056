import DocumentCompany from "../DocumentCompany";
import { INFOCERT_FLOW_TYPE } from "../enum/INFOCERT_FLOW_TYPE";
import { CompanyMember } from "./CompanyMember";

export class Company {
  id: number | undefined;
  dossierId: number | undefined;
  companyVat: string;
  vatNumber: string;
  companyName: string;
  businessName: string;
  registeredOffice: string;
  maxScore: number | undefined;
  approved: boolean | null;
  members: CompanyMember[];
  documents: DocumentCompany[];
  mode: INFOCERT_FLOW_TYPE;
  groupName: string;
  groupVAT: string;
  fundsSource: any;
  legalStatus: string;
  constitutionDate: string;
  aspsp: boolean | null;
  pep: boolean | null;
  pis: boolean | null;
  resp: boolean | null;
  atecoCode: string | null;
  hasSignaturePower: boolean | null;
  executorEmail: string | null;

  constructor(
    companyVat: string = "",
    companyName: string = "",
    businessName: string = "",
    vatNumber: string = "",
    registeredOffice: string = "",
    approved: boolean | null = null,
    members: CompanyMember[] = [],
    documents: DocumentCompany[] = [],
    mode: INFOCERT_FLOW_TYPE = INFOCERT_FLOW_TYPE.SINGLE,
    groupName: string = "",
    groupVAT: string = "",
    fundsSource: any = undefined,
    legalStatus: string = "",
    constitutionDate: string = "",
    aspsp: boolean | null = null,
    pep: boolean | null = null,
    pis: boolean | null = null,
    resp: boolean | null = null,
    atecoCode: string | null = null,
    hasSignaturePower: boolean | null = null,
    executorEmail: string | null = null
  ) {
    this.companyVat = companyVat;
    this.companyName = companyName;
    this.vatNumber = vatNumber;
    this.businessName = businessName;
    this.registeredOffice = registeredOffice;
    this.approved = approved;
    this.mode = mode;
    this.groupName = groupName;
    this.groupVAT = groupVAT;
    this.members = members;
    this.documents = documents;
    this.fundsSource = fundsSource;
    this.legalStatus = legalStatus;
    this.constitutionDate = constitutionDate;
    this.aspsp = aspsp;
    this.pep = pep;
    this.pis = pis;
    this.resp = resp;
    this.atecoCode = atecoCode;
    this.hasSignaturePower = hasSignaturePower;
    this.executorEmail = executorEmail;
  }
}

export enum COMPANY_RISK {
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low",
  NONE = "none",
}
