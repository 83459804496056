import { Chart } from "chart.js";
import "./ChartData.scss";
import { forwardRef, useEffect } from "react";
import { CHART_TYPE } from "../../core/constants/ChartType";
import THEME from "../../core/theme/theme";
import {
  CallbackParameters,
  ChartConfiguration,
  LineDataSet,
} from "../../core/types/UI/ChartConfiguration";

const ChartData = forwardRef(
  ((
    props: {
      configuration?: ChartConfiguration | undefined;
      chartRef?: any;
      tooltipRef?: any;
      renderTooltip?: any;
    },
    ref
  ) => {
    const ALT_COLOR = "#dfdede";
    const ALT_COLOR_HOVER = "#dfdede";
    const TEXT_COLOR = "#6c6c6c";
    const TITLE_COLOR = "#999999";
    const STROKE_COLOR = "#706f6f";
    const FONT_FAMILY = "'Nunito', sans-serif";
    const DEFAULT_BAR_PERCENTAGE = 0.5;

    let indexSelected: any;
    let chartObj: Chart;
    let gradient: any;

    const plugin = {
      afterDatasetsDraw: (chartInstance: any) => {
        if (
          chartInstance.tooltip._active &&
          chartInstance.tooltip._active.length
        ) {
          const activePoint = chartInstance.tooltip._active[0];
          const ctx = chartInstance.ctx;
          const yAxis = chartInstance.scales["y-axis-0"];
          const xAxis = chartInstance.scales["x-axis-0"];
          const x = activePoint.tooltipPosition().x;
          const topY = yAxis.top;
          const bottomY = yAxis.bottom;

          // draw line
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 2;
          ctx.strokeStyle = STROKE_COLOR;
          ctx.stroke();
          ctx.restore();
          const chartWidth = xAxis.right;
          const monthXOffset = x;
          const monthWidth = chartWidth / 12;

          indexSelected =
            chartWidth !== monthXOffset
              ? Math.floor(monthXOffset / monthWidth)
              : Math.floor((chartWidth - monthWidth / 2) / monthWidth);
        }
      },
    };

    const setUpRoundBorder = () => {
      const chart: any = Chart;
      chart.elements.Rectangle.prototype.draw = function () {
        let ctx = this._chart.ctx;
        let vm = this._view;
        let left, right, top, bottom, signX, signY, borderSkipped, radius;
        let borderWidth = vm.borderWidth;

        // If radius is less than 0 or is large enough to cause drawing errors a max
        //      radius is imposed. If cornerRadius is not defined set it to 0.
        let cornerRadius = this._chart.config.options.cornerRadius || 5;
        let fullCornerRadius =
          this._chart.config.options.fullCornerRadius || false;
        let stackedRounded = this._chart.config.options.stackedRounded || false;
        let typeOfChart = this._chart.config.type;

        if (!vm.horizontal) {
          // bar
          left = vm.x - vm.width / 2;
          right = vm.x + vm.width / 2;
          top = vm.y;
          bottom = vm.base;
          signX = 1;
          signY = bottom > top ? 1 : -1;
          borderSkipped = vm.borderSkipped || "bottom";
        } else {
          // horizontal bar
          left = vm.base;
          right = vm.x;
          top = vm.y - vm.height / 2;
          bottom = vm.y + vm.height / 2;
          signX = right > left ? 1 : -1;
          signY = 1;
          borderSkipped = vm.borderSkipped || "left";
        }

        // Canvas doesn't allow us to stroke inside the width so we can
        // adjust the sizes to fit if we're setting a stroke on the line
        if (borderWidth) {
          // borderWidth shold be less than bar width and bar height.
          let barSize = Math.min(
            Math.abs(left - right),
            Math.abs(top - bottom)
          );
          borderWidth = borderWidth > barSize ? barSize : borderWidth;
          let halfStroke = borderWidth / 2;
          // Adjust borderWidth when bar top position is near vm.base(zero).
          let borderLeft =
            left + (borderSkipped !== "left" ? halfStroke * signX : 0);
          let borderRight =
            right + (borderSkipped !== "right" ? -halfStroke * signX : 0);
          let borderTop =
            top + (borderSkipped !== "top" ? halfStroke * signY : 0);
          let borderBottom =
            bottom + (borderSkipped !== "bottom" ? -halfStroke * signY : 0);
          // not become a vertical line?
          if (borderLeft !== borderRight) {
            top = borderTop;
            bottom = borderBottom;
          }
          // not become a horizontal line?
          if (borderTop !== borderBottom) {
            left = borderLeft;
            right = borderRight;
          }
        }

        ctx.beginPath();
        ctx.fillStyle = vm.backgroundColor;
        ctx.strokeStyle = vm.borderColor;
        ctx.lineWidth = borderWidth;

        // Corner points, from bottom-left to bottom-right clockwise
        // | 1 2 |
        // | 0 3 |
        let corners = [
          [left, bottom],
          [left, top],
          [right, top],
          [right, bottom],
        ];

        // Find first (starting) corner with fallback to 'bottom'
        let borders = ["bottom", "left", "top", "right"];
        let startCorner = borders.indexOf(borderSkipped, 0);
        if (startCorner === -1) {
          startCorner = 0;
        }

        function cornerAt(index: any) {
          return corners[(startCorner + index) % 4];
        }

        // Draw rectangle from 'startCorner'
        let corner = cornerAt(0);
        ctx.moveTo(corner[0], corner[1]);

        let nextCornerId, nextCorner, width, height, x, y;
        for (let i = 1; i < 4; i++) {
          corner = cornerAt(i);
          nextCornerId = i + 1;
          if (nextCornerId == 4) {
            nextCornerId = 0;
          }

          nextCorner = cornerAt(nextCornerId);

          width = corners[2][0] - corners[1][0];
          height = corners[0][1] - corners[1][1];
          x = corners[1][0];
          y = corners[1][1];

          let radius = cornerRadius;
          // Fix radius being too large
          if (radius > Math.abs(height) / 2) {
            radius = Math.floor(Math.abs(height) / 2);
          }
          if (radius > Math.abs(width) / 2) {
            radius = Math.floor(Math.abs(width) / 2);
          }

          let x_tl, x_tr, y_tl, y_tr, x_bl, x_br, y_bl, y_br;
          if (height < 0) {
            // Negative values in a standard bar chart
            x_tl = x;
            x_tr = x + width;
            y_tl = y + height;
            y_tr = y + height;

            x_bl = x;
            x_br = x + width;
            y_bl = y;
            y_br = y;

            // Draw
            ctx.moveTo(x_bl + radius, y_bl);

            ctx.lineTo(x_br - radius, y_br);

            // bottom right
            ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius);

            ctx.lineTo(x_tr, y_tr + radius);

            // top right
            fullCornerRadius
              ? ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr)
              : ctx.lineTo(x_tr, y_tr, x_tr - radius, y_tr);

            ctx.lineTo(x_tl + radius, y_tl);

            // top left
            fullCornerRadius
              ? ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius)
              : ctx.lineTo(x_tl, y_tl, x_tl, y_tl + radius);

            ctx.lineTo(x_bl, y_bl - radius);

            //  bottom left
            ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);
          } else if (width < 0) {
            // Negative values in a horizontal bar chart
            x_tl = x + width;
            x_tr = x;
            y_tl = y;
            y_tr = y;

            x_bl = x + width;
            x_br = x;
            y_bl = y + height;
            y_br = y + height;

            // Draw
            ctx.moveTo(x_bl + radius, y_bl);

            ctx.lineTo(x_br - radius, y_br);

            //  Bottom right corner
            fullCornerRadius
              ? ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius)
              : ctx.lineTo(x_br, y_br, x_br, y_br - radius);

            ctx.lineTo(x_tr, y_tr + radius);

            // top right Corner
            fullCornerRadius
              ? ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr)
              : ctx.lineTo(x_tr, y_tr, x_tr - radius, y_tr);

            ctx.lineTo(x_tl + radius, y_tl);

            // top left corner
            ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius);

            ctx.lineTo(x_bl, y_bl - radius);

            //  bttom left corner
            ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);
          } else {
            let lastVisible = 0;
            for (
              let findLast = 0, findLastTo = this._chart.data.datasets.length;
              findLast < findLastTo;
              findLast++
            ) {
              if (!this._chart.getDatasetMeta(findLast).hidden) {
                lastVisible = findLast;
              }
            }
            let rounded = this._datasetIndex === lastVisible;

            if (rounded) {
              //Positive Value
              ctx.moveTo(x + radius, y);

              ctx.lineTo(x + width - radius, y);

              // top right
              ctx.quadraticCurveTo(x + width, y, x + width, y + radius);

              ctx.lineTo(x + width, y + height - radius);

              // bottom right
              if (fullCornerRadius || typeOfChart == "horizontalBar")
                ctx.quadraticCurveTo(
                  x + width,
                  y + height,
                  x + width - radius,
                  y + height
                );
              else ctx.lineTo(x + width, y + height);

              ctx.lineTo(x + radius, y + height);

              // bottom left
              if (fullCornerRadius)
                ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
              else ctx.lineTo(x, y + height);

              ctx.lineTo(x, y + radius);

              // top left
              if (fullCornerRadius || typeOfChart == "bar")
                ctx.quadraticCurveTo(x, y, x + radius, y);
              else ctx.lineTo(x, y);
            } else {
              ctx.moveTo(x, y);
              ctx.lineTo(x + width, y);
              ctx.lineTo(x + width, y + height);
              ctx.lineTo(x, y + height);
              ctx.lineTo(x, y);
            }
          }
        }

        ctx.fill();
        if (borderWidth) {
          ctx.stroke();
        }
      };
    };

    const getType = () => {
      switch (props.configuration?.type) {
        case CHART_TYPE.HORIZONTAL_BAR:
          return "horizontalBar";
        case CHART_TYPE.LINE:
          return "line";
        default:
          return "bar";
      }
    };

    const getDataSet = () => {
      if (props.configuration) {
        switch (props.configuration?.type) {
          case CHART_TYPE.SINGLE_BAR:
          case CHART_TYPE.HORIZONTAL_BAR: {
            return props.configuration.datasets.map((ds) => {
              return {
                data: ds.data,
                label: ds.label,
                backgroundColor: ds.backgroundColor
                  ? ds.backgroundColor
                  : THEME.palette?.secondary?.main,
                hoverBackgroundColor: ds.hoverBackgroundColor
                  ? ds.hoverBackgroundColor
                  : THEME.palette?.secondary?.hover,
              };
            });
          }
          case CHART_TYPE.MULTI_BAR: {
            for (let i = 0; i < props.configuration.datasets.length; i++) {
              if (!props.configuration.datasets[i].backgroundColor) {
                if (!props.configuration.datasets[i].backgroundColor) {
                  props.configuration.datasets[i].backgroundColor =
                    i === 0 ? THEME.palette?.secondary?.main : ALT_COLOR;
                }

                if (!props.configuration.datasets[i].hoverBackgroundColor) {
                  props.configuration.datasets[i].hoverBackgroundColor =
                    i === 0 ? THEME.palette?.secondary?.hover : ALT_COLOR_HOVER;
                }
              }
            }
            return props.configuration.datasets;
          }
          case CHART_TYPE.LINE: {
            return (props.configuration.datasets as LineDataSet[]).map((ds) => {
              return {
                data: ds.data,
                label: ds.label,
                backgroundColor: ds.backgroundColor
                  ? ds.backgroundColor
                  : gradient,
                pointBackgroundColor: "white",
                hoverBackgroundColor: ds.hoverBackgroundColor
                  ? ds.hoverBackgroundColor
                  : "white",
                borderColor: ds.borderColor
                  ? ds.borderColor
                  : THEME.palette?.secondary?.hover,
                borderWidth: ds.borderWidth ? ds.borderWidth : 1,
                pointRadius: ds.pointRadius ? ds.pointRadius : 3,
                pointHoverRadius: ds.pointHoverRadius ? ds.pointHoverRadius : 6,
                lineTension: ds.lineTension ? ds.lineTension : 0,
                fill: ds.fill ? ds.fill : true,
              };
            });
          }
        }
      }
    };

    const setGridColor = (val: number) => {
      if (props.configuration?.type === CHART_TYPE.LINE) {
        const gridList = [];
        for (let i = 0; i < 12; i++) {
          gridList.push("#d8d8d8");
        }
        if (val !== 0) {
          gridList[val] = THEME.palette?.secondary?.main;
        }
        return gridList;
      } else {
        return "#FFF";
      }
    };

    const setZeroLineColor = () => {
      if (props.configuration?.type === CHART_TYPE.LINE) {
        if (indexSelected === 0) {
          return THEME.palette?.secondary?.main;
        } else {
          return "#d8d8d8";
        }
      } else {
        return "#d8d8d8";
      }
    };

    const setLabels = (axes: any, value: any, idx: any, arr: any) => {
      if (axes.scales?.callbackFunction) {
        return axes.scales.callbackFunction(
          new CallbackParameters(value, idx, arr)
        );
      }

      if (axes.truncateAt && value.length > axes.truncateAt + 1) {
        return value.substring(0, axes.truncateAt) + ".";
      }

      return value;
    };

    const setChart = () => {
      if (chartObj) {
        chartObj.destroy();
      }

      gradient =
        props.chartRef &&
        props.chartRef.current &&
        props.chartRef?.current
          .getContext("2d")
          .createLinearGradient(0, 0, 0, 170);
      gradient.addColorStop(0, THEME.palette?.secondary?.main);
      gradient.addColorStop(1, "white");

      const config: any = {
        type: getType(),
        data: {
          labels: props.configuration?.intervals,
          datasets: getDataSet(),
        },
        options: {
          legend: {
            display: false,
          },
          title: {
            display: props.configuration?.title,
            text: props.configuration?.title,
          },
          tooltips: {
            enabled: !props.configuration?.showCustomTooltip,
            intersect: false,
            mode: "index",
            position: "nearest",
            titleFontFamily: FONT_FAMILY,
            titleFontSize: 12,
            titleFontColor: TITLE_COLOR,
            titleMarginBottom: 12,
            bodyFontFamily: FONT_FAMILY,
            bodyFontSize: 12,
            bodyFontColor: TITLE_COLOR,
            bodyAlign: "right", // 'left'
            cornerRadius: 10,
            borderWidth: 1,
            borderColor: ALT_COLOR,
            displayColors: true,
            backgroundColor: "white",
            xPadding: 10,
            yPadding: 10,
            filter: (tooltipItem: any, data: any) => {

              if (props.configuration && props.configuration?.filters?.filter) {
                props?.configuration.filters.filter(tooltipItem, data);
              }

              if (
                props.configuration &&
                props.configuration?.filters?.filterByNull
              ) {
                return (
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ] !== null
                );
              }

              return data.datasets[tooltipItem.datasetIndex].data[
                tooltipItem.index
              ];
            },
            callbacks: {
              title: (tooltipItem: any) => {
                if (props.configuration?.tooltip?.title) {
                  return props.configuration?.tooltip.title(tooltipItem);
                } /* else {
                return tooltipItem[0] ? props.configuration && props.configuration?.intervals[tooltipItem[0].index] : '' ;
              } */
              },
              label: (tooltipItem: any) => {

                if (props.configuration?.tooltip?.label) {
                  return props.configuration.tooltip.label(
                    props.configuration,
                    tooltipItem
                  );
                } else {
                  const label =
                    config.data.datasets[tooltipItem.datasetIndex].label;
                  const value =
                    config.data.datasets[tooltipItem.datasetIndex].data[
                      tooltipItem.index
                    ];

                  const result = label ? `${label}: ${value}` : value;
                  return [result];
                }
              },
              labelTextColor: (tooltipItem: any) => {
                if (props.configuration?.tooltip?.labelTextColor) {
                  return props.configuration.tooltip.labelTextColor(
                    tooltipItem
                  );
                }
                return TEXT_COLOR;
              },
            },
            custom: (tooltipModel: any) => {
              if (props.configuration?.showCustomTooltip) {
                let tooltipEl: any = props.tooltipRef?.current;

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
                }

                // Set caret Position
                tooltipEl.classList.remove(
                  "bottom",
                  "center",
                  "top",
                  "no-transform"
                );
                if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                  tooltipEl.classList.add("no-transform");
                }

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.position = "absolute";
                tooltipEl.style.left =
                  tooltipModel.x + tooltipModel.xPadding * 2 + "px";
                tooltipEl.style.top =
                  tooltipModel.y + tooltipModel.caretY + "px";
                tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                tooltipEl.style.padding =
                  tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
                tooltipEl.style.pointerEvents = "none";

                const bodyItems = tooltipModel.body.map(
                  (bodyItem: any) => bodyItem.lines
                );
                props.renderTooltip(bodyItems.length > 0 ? bodyItems[0] : []);
              }
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                stacked:
                  props.configuration &&
                  props.configuration.datasets.length > 1,
                scaleLabel: {
                  display: props.configuration?.xAxes?.label,
                  labelString: props.configuration?.xAxes?.label,
                },
                gridLines: {
                  color: setGridColor(indexSelected),
                  zeroLineColor: setZeroLineColor(),
                  tickMarkLength: 4,
                },
                ticks: {
                  maxRotation:
                    props.configuration?.xAxes?.rotation ||
                    props.configuration?.xAxes?.rotation === 0
                      ? props.configuration.xAxes.rotation
                      : 45,
                  minRotation:
                    props.configuration?.xAxes?.rotation ||
                    props.configuration?.xAxes?.rotation === 0
                      ? props.configuration.xAxes.rotation
                      : 45,
                  max: props?.configuration?.xAxes?.maxValue
                    ? props.configuration.xAxes.maxValue
                    : null,
                  stepSize: props?.configuration?.xAxes?.stepSize || 1,
                  padding: 10,
                  fontColor: TITLE_COLOR,
                  fontFamily: FONT_FAMILY,
                  callback: (value: any, idx: any, arr: any) =>
                    setLabels(props.configuration?.xAxes, value, idx, arr),
                },
                barPercentage: props.configuration?.xAxes?.barPercentage
                  ? props.configuration.xAxes.barPercentage
                  : DEFAULT_BAR_PERCENTAGE,
              },
            ],
            yAxes: [
              {
                display: true,
                stacked:
                  props.configuration &&
                  props.configuration.datasets.length > 1,
                maxRotation: props.configuration?.yAxes?.rotation
                  ? props?.configuration.yAxes.rotation
                  : 0,
                minRotation: props?.configuration?.yAxes?.rotation
                  ? props.configuration.yAxes.rotation
                  : 0,
                max: props.configuration?.yAxes?.maxValue
                  ? props.configuration.yAxes.maxValue
                  : 30,
                ticks: {
                  max: props.configuration?.yAxes?.maxValue
                    ? props.configuration.yAxes.maxValue
                    : null,
                  stepSize: props.configuration?.yAxes?.stepSize || 1,
                  beginAtZero: true,
                  padding: 4,
                  fontColor: TITLE_COLOR,
                  fontFamily: FONT_FAMILY,
                  callback: (value: any, idx: any, arr: any) =>
                    setLabels(props.configuration?.yAxes, value, idx, arr),
                },
                scaleLabel: {
                  display: props.configuration?.yAxes?.label,
                  labelString: props.configuration?.yAxes?.label,
                },
                gridLines: {
                  tickMarkLength: 4,
                },
                barPercentage: props.configuration?.yAxes?.barPercentage
                  ? props.configuration?.yAxes?.barPercentage
                  : DEFAULT_BAR_PERCENTAGE,
              },
            ],
          },
          animation: {
            duration: 0,
          },
          maintainAspectRatio: false,
          responsive: true,
        },
        plugins:
          props.configuration?.type !== CHART_TYPE.HORIZONTAL_BAR
            ? [plugin]
            : [],
      };
/*       const ctx = props.chartRef.current.getContext('2d');
      chartObj = new Chart(ctx, config); */
      const ctx: any = document.getElementById("myChart");
      chartObj = new Chart(ctx, config);

      /* const lineCanvas: any = document.getElementById('myChart');

      lineCanvas.onclick = (evt: any) => {
        const activePoint = this.chartObj.getElementsAtEvent(evt)[0];
        if (activePoint !== undefined) {
          const title = this.chartObj.data.labels[activePoint._index];
        }
      }; */
    };

    useEffect(() => {
      setUpRoundBorder();
      setChart();
    }, []);

    return (
      <div className="myChart chart-container">
        <canvas
          ref={props.chartRef}
          id="myChart"
          height={300}
          width={700}
        ></canvas>
        <div
          ref={props.tooltipRef}
          className="tooltipContainer"
          style={{ opacity: 0 }}
        ></div>
      </div>
    );
  })
);
export default ChartData;
