import { COMPANY_RISK } from "../types/entity/Company"

export const COMPANY_LEGAL_STATUS = (t: any) => [
    { value: "INDIVIDUAL_COMPANY", label: t('COMPANY_LEGAL_STATUS.INDIVIDUAL_COMPANY'), risk: COMPANY_RISK.LOW },
    { value: "PARTNERSHIP_COMPANY", label: t('COMPANY_LEGAL_STATUS.PARTNERSHIP_COMPANY'), risk: COMPANY_RISK.LOW },
    { value: "COLLECTIVE_COMPANY", label: t('COMPANY_LEGAL_STATUS.COLLECTIVE_COMPANY'), risk: COMPANY_RISK.LOW },
    { value: "SIMPLE_COMPANY", label: t('COMPANY_LEGAL_STATUS.SIMPLE_COMPANY'), risk: COMPANY_RISK.LOW },
    { value: "PROFESSIONAL ASSOCIATIONS", label: t('COMPANY_LEGAL_STATUS.PROFESSIONAL ASSOCIATIONS'), risk: COMPANY_RISK.MEDIUM},
    { value: "PUBLIC_ENTITIES", label: t('COMPANY_LEGAL_STATUS.PUBLIC_ENTITIES'), risk: COMPANY_RISK.MEDIUM },
    { value: "SCRL", label: t('COMPANY_LEGAL_STATUS.SCRL'),risk: COMPANY_RISK.MEDIUM},
    { value: "SCPA", label: t('COMPANY_LEGAL_STATUS.SCPA'),risk: COMPANY_RISK.MEDIUM },
    { value: "SPA", label: t('COMPANY_LEGAL_STATUS.SPA'),risk: COMPANY_RISK.MEDIUM},
    { value: "SRL", label: t('COMPANY_LEGAL_STATUS.SRL'),risk: COMPANY_RISK.MEDIUM },
    { value: "ITALIAN_FIDUCIARY", label: t('COMPANY_LEGAL_STATUS.ITALIAN_FIDUCIARY'),risk: COMPANY_RISK.MEDIUM },
    { value: "EXTERNAL_TRUST", label: t('COMPANY_LEGAL_STATUS.EXTERNAL_TRUST'), risk: COMPANY_RISK.HIGH },
    { value: "EXTERNAL_ITALIAN", label: t('COMPANY_LEGAL_STATUS.EXTERNAL_ITALIAN'), risk: COMPANY_RISK.HIGH },
    { value: "ART_36_38", label: t('COMPANY_LEGAL_STATUS.ART_36_38'), risk: COMPANY_RISK.HIGH },
    { value: "FOUNDATION", label: t('COMPANY_LEGAL_STATUS.FOUNDATION'), risk: COMPANY_RISK.HIGH },
    { value: "NON_PROFIT", label: t('COMPANY_LEGAL_STATUS.NON_PROFIT'), risk: COMPANY_RISK.HIGH } 
]

export const COMPANY_CONSTITUTION_DATE = (t: any) => [
    { value: "FIRST_RANGE", label: t('COMPANY_CONSTITUTION_DATE.FIRST_RANGE'), risk: COMPANY_RISK.LOW },
    { value: "SECOND_RANGE", label: t('COMPANY_CONSTITUTION_DATE.SECOND_RANGE'), risk: COMPANY_RISK.MEDIUM },
    { value: "THIRD_RANGE", label: t('COMPANY_CONSTITUTION_DATE.THIRD_RANGE'), risk: COMPANY_RISK.HIGH },
]

export const FOUND_SOURCES = (t: any) => [
    { value: "FATTURATO", label: t('FOUND_SOURCES.FATTURATO'), risk: COMPANY_RISK.LOW },
    { value: "RENDITA_FIN", label: t('FOUND_SOURCES.RENDITA_FIN'), risk: COMPANY_RISK.MEDIUM },
    { value: "VENDITA_MOB", label: t('FOUND_SOURCES.VENDITA_MOB'), risk: COMPANY_RISK.MEDIUM },
    { value: "RENDITA_IMM", label: t('FOUND_SOURCES.RENDITA_IMM'), risk: COMPANY_RISK.MEDIUM},
    { value: "VENDITA_IMM", label: t('FOUND_SOURCES.VENDITA_IMM'), risk: COMPANY_RISK.MEDIUM},
    { value: "CONFERIMENTO", label: t('FOUND_SOURCES.CONFERIMENTO'), risk: COMPANY_RISK.HIGH },
    { value: "EROGAZIONE", label: t('FOUND_SOURCES.EROGAZIONE'), risk: COMPANY_RISK.HIGH },
    { value: "DONAZIONI", label: t('FOUND_SOURCES.DONAZIONI'), risk: COMPANY_RISK.HIGH },
]

export const ATECO_CODE = (t:any) => [
    {value:"05.1", label: t('ATECO_CODE.ATECO_CODE_ESTRAZIONE_DI_ANTRACITE'),risk: COMPANY_RISK.NONE},
    {value:"05.2", label: t('ATECO_CODE.ATECO_CODE_ESTRAZIONE_DI_LIGNITE'),risk: COMPANY_RISK.NONE},
    {value:"07.1", label: t('ATECO_CODE.ATECO_CODE_ESTRAZIONE_DI_MINERALI_METALLIFERI_FERROSI'),risk: COMPANY_RISK.NONE},
    {value:"07.2", label: t('ATECO_CODE.ATECO_CODE_ESTRAZIONE_DI_MINERALI_METALLIFERI_NON_FERROSI'),risk: COMPANY_RISK.NONE},
    {value:"08.1", label: t('ATECO_CODE.ATECO_CODE_ESTRAZIONE_DI_PIETRA_SABBIA_E_ARGILLA'),risk: COMPANY_RISK.NONE},
    {value:"08.9", label: t('ATECO_CODE.ATECO_CODE_ESTRAZIONE_DI_MINERALI_DA_CAVE_E_MINIERE_NCA'),risk: COMPANY_RISK.NONE},
    {value:"09.1", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_SUPPORTO_ALL_ESTRAZIONE_DI_PETROLIO_E_DI_GAS_NATURALE'),risk: COMPANY_RISK.NONE},
    {value:"09.9", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_SUPPORTO_PER_LESTRAZIONE_DA_CAVE_E_MINIERE_DI_ALTRI_MINERALI'),risk: COMPANY_RISK.NONE},
    {value:"11.0", label: t('ATECO_CODE.ATECO_CODE_INDUSTRIA_DELLE_BEVANDE'),risk: COMPANY_RISK.NONE},
    {value:"12.0", label: t('ATECO_CODE.ATECO_CODE_INDUSTRIA_DEL_TABACCO'),risk: COMPANY_RISK.NONE},
    {value:"13.1", label: t('ATECO_CODE.ATECO_CODE_PREPARAZIONE_E_FILATURA_DI_FIBRE_TESSILI'),risk: COMPANY_RISK.NONE},
    {value:"13.2", label: t('ATECO_CODE.ATECO_CODE_TESSITURA'),risk: COMPANY_RISK.NONE},
    {value:"13.3", label: t('ATECO_CODE.ATECO_CODE_FINISSAGGIO_DEI_TESSILI'),risk: COMPANY_RISK.NONE},
    {value:"13.9", label: t('ATECO_CODE.ATECO_CODE_ALTRE_INDUSTRIE_TESSILI'),risk: COMPANY_RISK.NONE},
    {value:"16.2", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_PRODOTTI_IN_LEGNO_SUGHERO_PAGLIA_E_MATERIALI_DA_INTRECCIO'),risk: COMPANY_RISK.NONE},
    {value:"17.1", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_PASTA-CARTA_CARTA_E_CARTONE'),risk: COMPANY_RISK.NONE},
    {value:"17.2", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_ARTICOLI_DI_CARTA_E_CARTONE'),risk: COMPANY_RISK.NONE},
    {value:"18.1", label: t('ATECO_CODE.ATECO_CODE_STAMPA_E_SERVIZI_CONNESSI_ALLA_STAMPA'),risk: COMPANY_RISK.NONE},
    {value:"18.2", label: t('ATECO_CODE.ATECO_CODE_RIPRODUZIONE_DI_SUPPORTI_REGISTRATI'),risk: COMPANY_RISK.NONE},
    {value:"19.1", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_PRODOTTI_DI_COKERIA'),risk: COMPANY_RISK.NONE},
    {value:"19.2", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_PRODOTTI_DERIVANTI_DALLA_RAFFINAZIONE_DEL_PETROLIO'),risk: COMPANY_RISK.NONE},
    {value:"20.1", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_PRODOTTI_CHIMICI_DI_BASE_DI_FERTILIZZANTI_E_COMPOSTI_AZOTATI_DI_MATERIE_PLASTICHE_E_GOMMA_SINTETICA_IN_FORME_PRIMARIE'),risk: COMPANY_RISK.NONE},
    {value:"20.2", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_AGROFARMACI_E_DI_ALTRI_PRODOTTI_CHIMICI_PER_LAGRICOLTURA'),risk: COMPANY_RISK.NONE},
    {value:"20.3", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_PITTURE_VERNICI_E_SMALTI_INCHIOSTRI_DA_STAMPA_E_ADESIVI_SINTETICI'),risk: COMPANY_RISK.NONE},
    {value:"20.4", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_SAPONI_E_DETERGENTI_DI_PRODOTTI_PER_LA_PULIZIA_E_LA_LUCIDATURA_DI_PROFUMI_E_COSMETICI'),risk: COMPANY_RISK.NONE},
    {value:"20.5", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_ALTRI_PRODOTTI_CHIMICI'),risk: COMPANY_RISK.NONE},
    {value:"20.6", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_FIBRE_SINTETICHE_E_ARTIFICIALI'),risk: COMPANY_RISK.NONE},
    {value:"21.1", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_PRODOTTI_FARMACEUTICI_DI_BASE'),risk: COMPANY_RISK.NONE},
    {value:"21.2", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_MEDICINALI_E_PREPARATI_FARMACEUTICI'),risk: COMPANY_RISK.NONE},
    {value:"22.1", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_ARTICOLI_IN_GOMMA'),risk: COMPANY_RISK.NONE},
    {value:"22.2", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_ARTICOLI_IN_MATERIE_PLASTICHE'),risk: COMPANY_RISK.NONE},
    {value:"23.1", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_VETRO_E_DI_PRODOTTI_IN_VETRO'),risk: COMPANY_RISK.NONE},
    {value:"23.2", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_PRODOTTI_REFRATTARI'),risk: COMPANY_RISK.NONE},
    {value:"23.3", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_MATERIALI_DA_COSTRUZIONE_IN_TERRACOTTA'),risk: COMPANY_RISK.NONE},
    {value:"23.4", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_ALTRI_PRODOTTI_IN_PORCELLANA_E_IN_CERAMICA'),risk: COMPANY_RISK.NONE},
    {value:"23.5", label: t('ATECO_CODE.ATECO_CODE_PRODUZIONE_DI_CEMENTO_CALCE_E_GESSO'),risk: COMPANY_RISK.NONE},
    {value:"23.6", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_PRODOTTI_IN_CALCESTRUZZO_CEMENTO_E_GESSO'),risk: COMPANY_RISK.NONE},
    {value:"24.1", label: t('ATECO_CODE.ATECO_CODE_SIDERURGIA'),risk: COMPANY_RISK.NONE},
    {value:"24.2", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_TUBI_CONDOTTI_PROFILATI_CAVI_E_RELATIVI_ACCESSORI_IN_ACCIAIO_ESCLUSI_QUELLI_IN_ACCIAIO_COLATO'),risk: COMPANY_RISK.NONE},
    {value:"24.3", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_ALTRI_PRODOTTI_DELLA_PRIMA_TRASFORMAZIONE_DELLACCIAIO'),risk: COMPANY_RISK.NONE},
    {value:"24.4", label: t('ATECO_CODE.ATECO_CODE_PRODUZIONE_DI_METALLI_DI_BASE_PREZIOSI_E_ALTRI_METALLI_NON_FERROSI_TRATTAMENTO_DEI_COMBUSTIBILI_NUCLEARI'),risk: COMPANY_RISK.NONE},
    {value:"24.5", label: t('ATECO_CODE.ATECO_CODE_FONDERIE'),risk: COMPANY_RISK.NONE},
    {value:"25.1", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_ELEMENTI_DA_COSTRUZIONE_IN_METALLO'),risk: COMPANY_RISK.NONE},
    {value:"25.2", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_CISTERNE_SERBATOI_RADIATORI_E_CONTENITORI_IN_METALLO'),risk: COMPANY_RISK.NONE},
    {value:"25.3", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_GENERATORI_DI_VAPORE_ESCLUSI_I_CONTENITORI_IN_METALLO_PER_CALDAIE_PER_IL_RISCALDAMENTO_CENTRALE_AD_ACQUA_CALDA'),risk: COMPANY_RISK.NONE},
    {value:"25.5", label: t('ATECO_CODE.ATECO_CODE_FUCINATURA_IMBUTITURA_STAMPAGGIO_E_PROFILATURA_DEI_METALLI_METALLURGIA_DELLE_POLVERI'),risk: COMPANY_RISK.NONE},
    {value:"25.6", label: t('ATECO_CODE.ATECO_CODE_TRATTAMENTO_E_RIVESTIMENTO_DEI_METALLI_LAVORI_DI_MECCANICA_GENERALE'),risk: COMPANY_RISK.NONE},
    {value:"26.1", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_COMPONENTI_ELETTRONICI_E_SCHEDE_ELETTRONICHE'),risk: COMPANY_RISK.NONE},
    {value:"26.2", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_COMPUTER_E_UNITÀ_PERIFERICHE'),risk: COMPANY_RISK.NONE},
    {value:"26.3", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_APPARECCHIATURE_PER_LE_TELECOMUNICAZIONI'),risk: COMPANY_RISK.NONE},
    {value:"26.4", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_PRODOTTI_DI_ELETTRONICA_DI_CONSUMO_AUDIO_E_VIDEO'),risk: COMPANY_RISK.NONE},
    {value:"26.5", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_STRUMENTI_E_APPARECCHI_DI_MISURAZIONE_PROVA_E_NAVIGAZIONE_OROLOGI'),risk: COMPANY_RISK.NONE},
    {value:"26.6", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_STRUMENTI_PER_IRRADIAZIONE_APPARECCHIATURE_ELETTROMEDICALI_ED_ELETTROTERAPEUTICHE'),risk: COMPANY_RISK.NONE},
    {value:"26.7", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_STRUMENTI_OTTICI_E_ATTREZZATURE_FOTOGRAFICHE'),risk: COMPANY_RISK.NONE},
    {value:"26.8", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_SUPPORTI_MAGNETICI_ED_OTTICI'),risk: COMPANY_RISK.NONE},
    {value:"27.1", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_MOTORI_GENERATORI_E_TRASFORMATORI_ELETTRICI_E_DI_APPARECCHIATURE_PER_LA_DISTRIBUZIONE_E_IL_CONTROLLO_DELLELETTRICITÀ'),risk: COMPANY_RISK.NONE},
    {value:"27.2", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_BATTERIE_DI_PILE_ED_ACCUMULATORI_ELETTRICI'),risk: COMPANY_RISK.NONE},
    {value:"27.3", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_CABLAGGI_E_APPARECCHIATURE_DI_CABLAGGIO'),risk: COMPANY_RISK.NONE},
    {value:"27.4", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_APPARECCHIATURE_PER_ILLUMINAZIONE'),risk: COMPANY_RISK.NONE},
    {value:"27.5", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_APPARECCHI_PER_USO_DOMESTICO'),risk: COMPANY_RISK.NONE},
    {value:"27.9", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_ALTRE_APPARECCHIATURE_ELETTRICHE'),risk: COMPANY_RISK.NONE},
    {value:"28.1", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_MACCHINE_DI_IMPIEGO_GENERALE'),risk: COMPANY_RISK.NONE},
    {value:"28.2", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_ALTRE_MACCHINE_DI_IMPIEGO_GENERALE'),risk: COMPANY_RISK.NONE},
    {value:"28.3", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_MACCHINE_PER_LAGRICOLTURA_E_LA_SILVICOLTURA'),risk: COMPANY_RISK.NONE},
    {value:"28.4", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_MACCHINE_PER_LA_FORMATURA_DEI_METALLI_E_DI_ALTRE_MACCHINE_UTENSILI'),risk: COMPANY_RISK.NONE},
    {value:"28.9", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_ALTRE_MACCHINE_PER_IMPIEGHI_SPECIALI'),risk: COMPANY_RISK.NONE},
    {value:"29.1", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_AUTOVEICOLI'),risk: COMPANY_RISK.NONE},
    {value:"29.2", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_CARROZZERIE_PER_AUTOVEICOLI_RIMORCHI_E_SEMIRIMORCHI'),risk: COMPANY_RISK.NONE},
    {value:"29.3", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_PARTI_ED_ACCESSORI_PER_AUTOVEICOLI_E_LORO_MOTORI'),risk: COMPANY_RISK.NONE},
    {value:"30.1", label: t('ATECO_CODE.ATECO_CODE_COSTRUZIONE_DI_NAVI_E_IMBARCAZIONI'),risk: COMPANY_RISK.NONE},
    {value:"30.2", label: t('ATECO_CODE.ATECO_CODE_COSTRUZIONE_DI_LOCOMOTIVE_E_DI_MATERIALE_ROTABILE_FERRO-TRANVIARIO'),risk: COMPANY_RISK.NONE},
    {value:"30.3", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_AEROMOBILI_DI_VEICOLI_SPAZIALI_E_DEI_RELATIVI_DISPOSITIVI'),risk: COMPANY_RISK.NONE},
    {value:"30.4", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_VEICOLI_MILITARI_DA_COMBATTIMENTO'),risk: COMPANY_RISK.NONE},
    {value:"30.9", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_MEZZI_DI_TRASPORTO_NCA'),risk: COMPANY_RISK.NONE},
    {value:"32.5", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_STRUMENTI_E_FORNITURE_MEDICHE_E_DENTISTICHE'),risk: COMPANY_RISK.NONE},
    {value:"32.9", label: t('ATECO_CODE.ATECO_CODE_INDUSTRIE_MANIFATTURIERE_NCA'),risk: COMPANY_RISK.NONE},
    {value:"35.3", label: t('ATECO_CODE.ATECO_CODE_FORNITURA_DI_VAPORE_E_ARIA_CONDIZIONATA'),risk: COMPANY_RISK.NONE},
    {value:"36.0", label: t('ATECO_CODE.ATECO_CODE_RACCOLTA_TRATTAMENTO_E_FORNITURA_DI_ACQUA'),risk: COMPANY_RISK.NONE},
    {value:"37.0", label: t('ATECO_CODE.ATECO_CODE_GESTIONE_DELLE_RETI_FOGNARIE'),risk: COMPANY_RISK.NONE},
    {value:"49.1", label: t('ATECO_CODE.ATECO_CODE_TRASPORTO_FERROVIARIO_DI_PASSEGGERI_(INTERURBANO)'),risk: COMPANY_RISK.NONE},
    {value:"49.2", label: t('ATECO_CODE.ATECO_CODE_TRASPORTO_FERROVIARIO_DI_MERCI'),risk: COMPANY_RISK.NONE},
    {value:"49.3", label: t('ATECO_CODE.ATECO_CODE_ALTRI_TRASPORTI_TERRESTRI_DI_PASSEGGERI'),risk: COMPANY_RISK.NONE},
    {value:"49.5", label: t('ATECO_CODE.ATECO_CODE_TRASPORTO_MEDIANTE_CONDOTTE'),risk: COMPANY_RISK.NONE},
    {value:"50.1", label: t('ATECO_CODE.ATECO_CODE_TRASPORTO_MARITTIMO_E_COSTIERO_DI_PASSEGGERI'),risk: COMPANY_RISK.NONE},
    {value:"50.2", label: t('ATECO_CODE.ATECO_CODE_TRASPORTO_MARITTIMO_E_COSTIERO_DI_MERCI'),risk: COMPANY_RISK.NONE},
    {value:"50.3", label: t('ATECO_CODE.ATECO_CODE_TRASPORTO_DI_PASSEGGERI_PER_VIE_DACQUA_INTERNE'),risk: COMPANY_RISK.NONE},
    {value:"50.4", label: t('ATECO_CODE.ATECO_CODE_TRASPORTO_DI_MERCI_PER_VIE_DACQUA_INTERNE'),risk: COMPANY_RISK.NONE},
    {value:"51.1", label: t('ATECO_CODE.ATECO_CODE_TRASPORTO_AEREO_DI_PASSEGGERI'),risk: COMPANY_RISK.NONE},
    {value:"51.2", label: t('ATECO_CODE.ATECO_CODE_TRASPORTO_AEREO_DI_MERCI_E_TRASPORTO_SPAZIALE'),risk: COMPANY_RISK.NONE},
    {value:"53.1", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_POSTALI_CON_OBBLIGO_DI_SERVIZIO_UNIVERSALE'),risk: COMPANY_RISK.NONE},
    {value:"53.2", label: t('ATECO_CODE.ATECO_CODE_ALTRE_ATTIVITÀ_POSTALI_E_DI_CORRIERE'),risk: COMPANY_RISK.NONE},
    {value:"60.2", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_PROGRAMMAZIONE_E_TRASMISSIONI_TELEVISIVE'),risk: COMPANY_RISK.NONE},
    {value:"61.1", label: t('ATECO_CODE.ATECO_CODE_TELECOMUNICAZIONI_FISSE'),risk: COMPANY_RISK.NONE},
    {value:"61.2", label: t('ATECO_CODE.ATECO_CODE_TELECOMUNICAZIONI_MOBILI'),risk: COMPANY_RISK.NONE},
    {value:"61.3", label: t('ATECO_CODE.ATECO_CODE_TELECOMUNICAZIONI_SATELLITARI'),risk: COMPANY_RISK.NONE},
    {value:"61.9", label: t('ATECO_CODE.ATECO_CODE_ALTRE_ATTIVITÀ_DI_TELECOMUNICAZIONE'),risk: COMPANY_RISK.NONE},
    {value:"64.1", label: t('ATECO_CODE.ATECO_CODE_INTERMEDIAZIONE_MONETARIA'),risk: COMPANY_RISK.NONE},
    {value:"64.2", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DELLE_SOCIETÀ_DI_PARTECIPAZIONE_(HOLDING)'),risk: COMPANY_RISK.NONE},
    {value:"64.3", label: t('ATECO_CODE.ATECO_CODE_SOCIETÀ_FIDUCIARIE_FONDI_E_ALTRE_SOCIETÀ_SIMILI'),risk: COMPANY_RISK.NONE},
    {value:"64.9", label: t('ATECO_CODE.ATECO_CODE_ALTRE_ATTIVITÀ_DI_SERVIZI_FINANZIARI_(ESCLUSE_LE_ASSICURAZIONI_E_I_FONDI_PENSIONE)'),risk: COMPANY_RISK.NONE},
    {value:"65.1", label: t('ATECO_CODE.ATECO_CODE_ASSICURAZIONI'),risk: COMPANY_RISK.NONE},
    {value:"65.2", label: t('ATECO_CODE.ATECO_CODE_RIASSICURAZIONI'),risk: COMPANY_RISK.NONE},
    {value:"65.3", label: t('ATECO_CODE.ATECO_CODE_FONDI_PENSIONE'),risk: COMPANY_RISK.NONE},
    {value:"66.1", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_AUSILIARIE_DEI_SERVIZI_FINANZIARI_(ESCLUSE_LE_ASSICURAZIONI_E_I_FONDI_PENSIONE)'),risk: COMPANY_RISK.NONE},
    {value:"66.2", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_AUSILIARIE_DELLE_ASSICURAZIONI_E_DEI_FONDI_PENSIONE'),risk: COMPANY_RISK.NONE},
    {value:"66.3", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_GESTIONE_DEI_FONDI'),risk: COMPANY_RISK.NONE},
    {value:"70.1", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_DIREZIONE_AZIENDALE'),risk: COMPANY_RISK.NONE},
    {value:"70.2", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_CONSULENZA_GESTIONALE'),risk: COMPANY_RISK.NONE},
    {value:"72.1", label: t('ATECO_CODE.ATECO_CODE_RICERCA_E_SVILUPPO_SPERIMENTALE_NEL_CAMPO_DELLE_SCIENZE_NATURALI_E_DELLINGEGNERIA'),risk: COMPANY_RISK.NONE},
    {value:"72.2", label: t('ATECO_CODE.ATECO_CODE_RICERCA_E_SVILUPPO_SPERIMENTALE_NEL_CAMPO_DELLE_SCIENZE_SOCIALI_E_UMANISTICHE'),risk: COMPANY_RISK.NONE},
    {value:"75.0", label: t('ATECO_CODE.ATECO_CODE_SERVIZI_VETERINARI'),risk: COMPANY_RISK.NONE},
    {value:"77.4", label: t('ATECO_CODE.ATECO_CODE_CONCESSIONE_DEI_DIRITTI_DI_SFRUTTAMENTO_DI_PROPRIETÀ_INTELLETTUALE_E_PRODOTTI_SIMILI_(ESCLUSE_LE_OPERE_PROTETTE_DAL_COPYRIGHT)'),risk: COMPANY_RISK.NONE},
    {value:"84.1", label: t('ATECO_CODE.ATECO_CODE_AMMINISTRAZIONE_PUBBLICA_AMMINISTRAZIONE_GENERALE_ECONOMICA_E_SOCIALE'),risk: COMPANY_RISK.NONE},
    {value:"84.2", label: t('ATECO_CODE.ATECO_CODE_SERVIZI_COLLETTIVI_DELLE_AMMINISTRAZIONI_PUBBLICHE'),risk: COMPANY_RISK.NONE},
    {value:"84.3", label: t('ATECO_CODE.ATECO_CODE_ASSICURAZIONE_SOCIALE_OBBLIGATORIA'),risk: COMPANY_RISK.NONE},
    {value:"85.1", label: t('ATECO_CODE.ATECO_CODE_ISTRUZIONE_PRESCOLASTICA'),risk: COMPANY_RISK.NONE},
    {value:"85.2", label: t('ATECO_CODE.ATECO_CODE_ISTRUZIONE_PRIMARIA'),risk: COMPANY_RISK.NONE},
    {value:"85.3", label: t('ATECO_CODE.ATECO_CODE_ISTRUZIONE_SECONDARIA'),risk: COMPANY_RISK.NONE},
    {value:"85.4", label: t('ATECO_CODE.ATECO_CODE_ISTRUZIONE_POST-SECONDARIA_UNIVERSITARIA_E_NON_UNIVERSITARIA'),risk: COMPANY_RISK.NONE},
    {value:"85.5", label: t('ATECO_CODE.ATECO_CODE_ALTRI_SERVIZI_DI_ISTRUZIONE'),risk: COMPANY_RISK.NONE},
    {value:"85.6", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_SUPPORTO_ALLISTRUZIONE'),risk: COMPANY_RISK.NONE},
    {value:"86.1", label: t('ATECO_CODE.ATECO_CODE_SERVIZI_OSPEDALIERI'),risk: COMPANY_RISK.NONE},
    {value:"87.1", label: t('ATECO_CODE.ATECO_CODE_STRUTTURE_DI_ASSISTENZA_INFERMIERISTICA_RESIDENZIALE'),risk: COMPANY_RISK.NONE},
    {value:"87.2", label: t('ATECO_CODE.ATECO_CODE_STRUTTURE_DI_ASSISTENZA_RESIDENZIALE_PER_PERSONE_AFFETTE_DA_RITARDI_MENTALI_DISTURBI_MENTALI_O_CHE_ABUSANO_DI_SOSTANZE_STUPEFACENTI'),risk: COMPANY_RISK.NONE},
    {value:"87.3", label: t('ATECO_CODE.ATECO_CODE_STRUTTURE_DI_ASSISTENZA_RESIDENZIALE_PER_ANZIANI_E_DISABILI'),risk: COMPANY_RISK.NONE},
    {value:"87.9", label: t('ATECO_CODE.ATECO_CODE_ALTRE_STRUTTURE_DI_ASSISTENZA_SOCIALE_RESIDENZIALE'),risk: COMPANY_RISK.NONE},
    {value:"88.1", label: t('ATECO_CODE.ATECO_CODE_ASSISTENZA_SOCIALE_NON_RESIDENZIALE_PER_ANZIANI_E_DISABILI'),risk: COMPANY_RISK.NONE},
    {value:"88.9", label: t('ATECO_CODE.ATECO_CODE_ALTRE_ATTIVITÀ_DI_ASSISTENZA_SOCIALE_NON_RESIDENZIALE'),risk: COMPANY_RISK.NONE},
    {value:"97.0", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_FAMIGLIE_E_CONVIVENZE_COME_DATORI_DI_LAVORO_PER_PERSONALE_DOMESTICO'),risk: COMPANY_RISK.NONE},
    {value:"98.1", label: t('ATECO_CODE.ATECO_CODE_PRODUZIONE_DI_BENI_INDIFFERENZIATI_PER_USO_PROPRIO_DA_PARTE_DI_FAMIGLIE_E_CONVIVENZE'),risk: COMPANY_RISK.NONE},
    {value:"98.2", label: t('ATECO_CODE.ATECO_CODE_PRODUZIONE_DI_SERVIZI_INDIFFERENZIATI_PER_USO_PROPRIO_DA_PARTE_DI_FAMIGLIE_E_CONVIVENZE'),risk: COMPANY_RISK.NONE},
    {value:"99.0", label: t('ATECO_CODE.ATECO_CODE_ORGANIZZAZIONI_ED_ORGANISMI_EXTRATERRITORIALI'),risk: COMPANY_RISK.LOW},
    {value:"10.1", label: t('ATECO_CODE.ATECO_CODE_LAVORAZIONE_E_CONSERVAZIONE_DI_CARNE_E_PRODUZIONE_DI_PRODOTTI_A_BASE_DI_CARNE'),risk: COMPANY_RISK.LOW},
    {value:"10.2", label: t('ATECO_CODE.ATECO_CODE_LAVORAZIONE_E_CONSERVAZIONE_DI_PESCE_CROSTACEI_E_MOLLUSCHI'),risk: COMPANY_RISK.LOW},
    {value:"10.3", label: t('ATECO_CODE.ATECO_CODE_LAVORAZIONE_E_CONSERVAZIONE_DI_FRUTTA_E_ORTAGGI'),risk: COMPANY_RISK.LOW},
    {value:"10.4", label: t('ATECO_CODE.ATECO_CODE_PRODUZIONE_DI_OLI_E_GRASSI_VEGETALI_E_ANIMALI'),risk: COMPANY_RISK.LOW},
    {value:"10.5", label: t('ATECO_CODE.ATECO_CODE_INDUSTRIA_LATTIERO-CASEARIA'),risk: COMPANY_RISK.LOW},
    {value:"10.6", label: t('ATECO_CODE.ATECO_CODE_LAVORAZIONE_DELLE_GRANAGLIE_PRODUZIONE_DI_AMIDI_E_DI_PRODOTTI_AMIDACEI'),risk: COMPANY_RISK.LOW},
    {value:"10.7", label: t('ATECO_CODE.ATECO_CODE_PRODUZIONE_DI_PRODOTTI_DA_FORNO_E_FARINACEI'),risk: COMPANY_RISK.LOW},
    {value:"10.8", label: t('ATECO_CODE.ATECO_CODE_PRODUZIONE_DI_ALTRI_PRODOTTI_ALIMENTARI'),risk: COMPANY_RISK.LOW},
    {value:"10.9", label: t('ATECO_CODE.ATECO_CODE_PRODUZIONE_DI_PRODOTTI_PER_LALIMENTAZIONE_DEGLI_ANIMALI'),risk: COMPANY_RISK.LOW},
    {value:"14.1", label: t('ATECO_CODE.ATECO_CODE_CONFEZIONE_DI_ARTICOLI_DI_ABBIGLIAMENTO_ESCLUSO_ABBIGLIAMENTO_IN_PELLICCIA'),risk: COMPANY_RISK.LOW},
    {value:"14.2", label: t('ATECO_CODE.ATECO_CODE_CONFEZIONE_DI_ARTICOLI_IN_PELLICCIA'),risk: COMPANY_RISK.LOW},
    {value:"14.3", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_ARTICOLI_DI_MAGLIERIA'),risk: COMPANY_RISK.LOW},
    {value:"15.1", label: t('ATECO_CODE.ATECO_CODE_PREPARAZIONE_E_CONCIA_DEL_CUOIO_FABBRICAZIONE_DI_ARTICOLI_DA_VIAGGIO_BORSE_PELLETTERIA_E_SELLERIA_PREPARAZIONE_E_TINTURA_DI_PELLICCE'),risk: COMPANY_RISK.LOW},
    {value:"15.2", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_CALZATURE'),risk: COMPANY_RISK.LOW},
    {value:"16.1", label: t('ATECO_CODE.ATECO_CODE_TAGLIO_E_PIALLATURA_DEL_LEGNO'),risk: COMPANY_RISK.LOW},
    {value:"23.7", label: t('ATECO_CODE.ATECO_CODE_TAGLIO_MODELLATURA_E_FINITURA_DI_PIETRE'),risk: COMPANY_RISK.LOW},
    {value:"23.9", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_PRODOTTI_ABRASIVI_E_DI_PRODOTTI_IN_MINERALI_NON_METALLIFERI_NCA'),risk: COMPANY_RISK.LOW},
    {value:"25.7", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_ARTICOLI_DI_COLTELLERIA_UTENSILI_E_OGGETTI_DI_FERRAMENTA'),risk: COMPANY_RISK.LOW},
    {value:"25.9", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_ALTRI_PRODOTTI_IN_METALLO'),risk: COMPANY_RISK.LOW},
    {value:"31.0", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_MOBILI'),risk: COMPANY_RISK.LOW},
    {value:"32.2", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_STRUMENTI_MUSICALI'),risk: COMPANY_RISK.LOW},
    {value:"32.3", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_ARTICOLI_SPORTIVI'),risk: COMPANY_RISK.LOW},
    {value:"32.4", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_GIOCHI_E_GIOCATTOLI'),risk: COMPANY_RISK.LOW},
    {value:"49.4", label: t('ATECO_CODE.ATECO_CODE_TRASPORTO_DI_MERCI_SU_STRADA_E_SERVIZI_DI_TRASLOCO'),risk: COMPANY_RISK.LOW},
    {value:"52.1", label: t('ATECO_CODE.ATECO_CODE_MAGAZZINAGGIO_E_CUSTODIA'),risk: COMPANY_RISK.LOW},
    {value:"52.2", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_SUPPORTO_AI_TRASPORTI'),risk: COMPANY_RISK.LOW},
    {value:"58.1", label: t('ATECO_CODE.ATECO_CODE_EDIZIONE_DI_LIBRI_PERIODICI_ED_ALTRE_ATTIVITÀ_EDITORIALI'),risk: COMPANY_RISK.LOW},
    {value:"58.2", label: t('ATECO_CODE.ATECO_CODE_EDIZIONE_DI_SOFTWARE'),risk: COMPANY_RISK.LOW},
    {value:"59.1", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_PRODUZIONE_CINEMATOGRAFICA_DI_VIDEO_E_DI_PROGRAMMI_TELEVISIVI'),risk: COMPANY_RISK.LOW},
    {value:"59.2", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_REGISTRAZIONE_SONORA_E_DI_EDITORIA_MUSICALE'),risk: COMPANY_RISK.LOW},
    {value:"60.1", label: t('ATECO_CODE.ATECO_CODE_TRASMISSIONI_RADIOFONICHE'),risk: COMPANY_RISK.LOW},
    {value:"62.0", label: t('ATECO_CODE.ATECO_CODE_PRODUZIONE_DI_SOFTWARE_CONSULENZA_INFORMATICA_E_ATTIVITÀ_CONNESSE'),risk: COMPANY_RISK.LOW},
    {value:"63.1", label: t('ATECO_CODE.ATECO_CODE_ELABORAZIONE_DEI_DATI_HOSTING_E_ATTIVITÀ_CONNESSE_PORTALI_WEB'),risk: COMPANY_RISK.LOW},
    {value:"63.9", label: t('ATECO_CODE.ATECO_CODE_ALTRE_ATTIVITÀ_DEI_SERVIZI_DINFORMAZIONE'),risk: COMPANY_RISK.LOW},
    {value:"69.1", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DEGLI_STUDI_LEGALI'),risk: COMPANY_RISK.LOW},
    {value:"69.2", label: t('ATECO_CODE.ATECO_CODE_CONTABILITÀ_CONTROLLO_E_REVISIONE_CONTABILE_CONSULENZA_IN_MATERIA_FISCALE_E_DEL_LAVORO'),risk: COMPANY_RISK.LOW},
    {value:"71.1", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DEGLI_STUDI_DI_ARCHITETTURA_INGEGNERIA_ED_ALTRI_STUDI_TECNICI'),risk: COMPANY_RISK.LOW},
    {value:"71.2", label: t('ATECO_CODE.ATECO_CODE_COLLAUDI_ED_ANALISI_TECNICHE'),risk: COMPANY_RISK.LOW},
    {value:"74.1", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_DESIGN_SPECIALIZZATE'),risk: COMPANY_RISK.LOW},
    {value:"74.2", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_FOTOGRAFICHE'),risk: COMPANY_RISK.LOW},
    {value:"74.3", label: t('ATECO_CODE.ATECO_CODE_TRADUZIONE_E_INTERPRETARIATO'),risk: COMPANY_RISK.LOW},
    {value:"74.9", label: t('ATECO_CODE.ATECO_CODE_ALTRE_ATTIVITÀ_PROFESSIONALI_SCIENTIFICHE_E_TECNICHE_NCA'),risk: COMPANY_RISK.LOW},
    {value:"78.1", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_AGENZIE_DI_COLLOCAMENTO'),risk: COMPANY_RISK.LOW},
    {value:"78.2", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DELLE_AGENZIE_DI_LAVORO_TEMPORANEO_(INTERINALE)'),risk: COMPANY_RISK.LOW},
    {value:"78.3", label: t('ATECO_CODE.ATECO_CODE_ALTRE_ATTIVITÀ_DI_FORNITURA_E_GESTIONE_DI_RISORSE_UMANE'),risk: COMPANY_RISK.LOW},
    {value:"80.1", label: t('ATECO_CODE.ATECO_CODE_SERVIZI_DI_VIGILANZA_PRIVATA'),risk: COMPANY_RISK.LOW},
    {value:"80.2", label: t('ATECO_CODE.ATECO_CODE_SERVIZI_CONNESSI_AI_SISTEMI_DI_VIGILANZA'),risk: COMPANY_RISK.LOW},
    {value:"80.3", label: t('ATECO_CODE.ATECO_CODE_SERVIZI_INVESTIGATIVI_PRIVATI'),risk: COMPANY_RISK.LOW},
    {value:"81.1", label: t('ATECO_CODE.ATECO_CODE_SERVIZI_INTEGRATI_DI_GESTIONE_AGLI_EDIFICI'),risk: COMPANY_RISK.LOW},
    {value:"81.3", label: t('ATECO_CODE.ATECO_CODE_CURA_E_MANUTENZIONE_DEL_PAESAGGIO'),risk: COMPANY_RISK.LOW},
    {value:"82.1", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_SUPPORTO_PER_LE_FUNZIONI_DUFFICIO'),risk: COMPANY_RISK.LOW},
    {value:"82.9", label: t('ATECO_CODE.ATECO_CODE_SERVIZI_DI_SUPPORTO_ALLE_IMPRESE_NCA'),risk: COMPANY_RISK.LOW},
    {value:"86.2", label: t('ATECO_CODE.ATECO_CODE_SERVIZI_DEGLI_STUDI_MEDICI_E_ODONTOIATRICI'),risk: COMPANY_RISK.LOW},
    {value:"86.9", label: t('ATECO_CODE.ATECO_CODE_ALTRI_SERVIZI_DI_ASSISTENZA_SANITARIA'),risk: COMPANY_RISK.LOW},
    {value:"91.0", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_BIBLIOTECHE_ARCHIVI_MUSEI_ED_ALTRE_ATTIVITÀ_CULTURALI'),risk: COMPANY_RISK.LOW},
    {value:"94.1", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_ORGANIZZAZIONI_ECONOMICHE_DI_DATORI_DI_LAVORO_E_PROFESSIONALI'),risk: COMPANY_RISK.LOW},
    {value:"94.2", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DEI_SINDACATI_DI_LAVORATORI_DIPENDENTI'),risk: COMPANY_RISK.LOW},
    {value:"94.9", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_ALTRE_ORGANIZZAZIONI_ASSOCIATIVE'),risk: COMPANY_RISK.LOW},
    {value:"95.1", label: t('ATECO_CODE.ATECO_CODE_RIPARAZIONE_DI_COMPUTER_E_DI_APPARECCHIATURE_PER_LE_COMUNICAZIONI'),risk: COMPANY_RISK.LOW},
    {value:"95.2", label: t('ATECO_CODE.ATECO_CODE_RIPARAZIONE_DI_BENI_PER_USO_PERSONALE_E_PER_LA_CASA'),risk: COMPANY_RISK.LOW},
    {value:"96.0", label: t('ATECO_CODE.ATECO_CODE_ALTRE_ATTIVITÀ_DI_SERVIZI_PER_LA_PERSONA'),risk: COMPANY_RISK.LOW},
    {value:"01.1", label: t('ATECO_CODE.ATECO_CODE_COLTIVAZIONE_DI_COLTURE_AGRICOLE_NON_PERMANENTI'),risk: COMPANY_RISK.MEDIUM},
    {value:"01.2", label: t('ATECO_CODE.ATECO_CODE_COLTIVAZIONE_DI_COLTURE_PERMANENTI'),risk: COMPANY_RISK.MEDIUM},
    {value:"01.3", label: t('ATECO_CODE.ATECO_CODE_RIPRODUZIONE_DELLE_PIANTE'),risk: COMPANY_RISK.MEDIUM},
    {value:"01.4", label: t('ATECO_CODE.ATECO_CODE_ALLEVAMENTO_DI_ANIMALI'),risk: COMPANY_RISK.MEDIUM},
    {value:"01.5", label: t('ATECO_CODE.ATECO_CODE_COLTIVAZIONI_AGRICOLE_ASSOCIATE_ALLALLEVAMENTO_DI_ANIMALI:_ATTIVITÀ_MISTA'),risk: COMPANY_RISK.MEDIUM},
    {value:"01.6", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_SUPPORTO_ALLAGRICOLTURA_E_ATTIVITÀ_SUCCESSIVE_ALLA_RACCOLTA'),risk: COMPANY_RISK.MEDIUM},
    {value:"01.7", label: t('ATECO_CODE.ATECO_CODE_CACCIA_CATTURA_DI_ANIMALI_E_SERVIZI_CONNESSI'),risk: COMPANY_RISK.MEDIUM},
    {value:"02.1", label: t('ATECO_CODE.ATECO_CODE_SILVICOLTURA_ED_ALTRE_ATTIVITÀ_FORESTALI'),risk: COMPANY_RISK.MEDIUM},
    {value:"02.2", label: t('ATECO_CODE.ATECO_CODE_UTILIZZO_DI_AREE_FORESTALI'),risk: COMPANY_RISK.MEDIUM},
    {value:"02.3", label: t('ATECO_CODE.ATECO_CODE_RACCOLTA_DI_PRODOTTI_SELVATICI_NON_LEGNOSI'),risk: COMPANY_RISK.MEDIUM},
    {value:"02.4", label: t('ATECO_CODE.ATECO_CODE_SERVIZI_DI_SUPPORTO_PER_LA_SILVICOLTURA'),risk: COMPANY_RISK.MEDIUM},
    {value:"03.1", label: t('ATECO_CODE.ATECO_CODE_PESCA'),risk: COMPANY_RISK.MEDIUM},
    {value:"03.2", label: t('ATECO_CODE.ATECO_CODE_ACQUACOLTURA'),risk: COMPANY_RISK.MEDIUM},
    {value:"32.1", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_GIOIELLERIA_BIGIOTTERIA_E_ARTICOLI_CONNESSI_LAVORAZIONE_DELLE_PIETRE_PREZIOSE'),risk: COMPANY_RISK.MEDIUM},
    {value:"33.1", label: t('ATECO_CODE.ATECO_CODE_RIPARAZIONE_E_MANUTENZIONE_DI_PRODOTTI_IN_METALLO_MACCHINE_ED_APPARECCHIATURE'),risk: COMPANY_RISK.MEDIUM},
    {value:"33.2", label: t('ATECO_CODE.ATECO_CODE_INSTALLAZIONE_DI_MACCHINE_ED_APPARECCHIATURE_INDUSTRIALI'),risk: COMPANY_RISK.MEDIUM},
    {value:"41.1", label: t('ATECO_CODE.ATECO_CODE_SVILUPPO_DI_PROGETTI_IMMOBILIARI'),risk: COMPANY_RISK.MEDIUM},
    {value:"43.2", label: t('ATECO_CODE.ATECO_CODE_INSTALLAZIONE_DI_IMPIANTI_ELETTRICI_IDRAULICI_ED_ALTRI_LAVORI_DI_COSTRUZIONE_E_INSTALLAZIONE'),risk: COMPANY_RISK.MEDIUM},
    {value:"43.3", label: t('ATECO_CODE.ATECO_CODE_COMPLETAMENTO_E_FINITURA_DI_EDIFICI'),risk: COMPANY_RISK.MEDIUM},
    {value:"43.9", label: t('ATECO_CODE.ATECO_CODE_ALTRI_LAVORI_SPECIALIZZATI_DI_COSTRUZIONE'),risk: COMPANY_RISK.MEDIUM},
    {value:"45.3", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_DI_PARTI_E_ACCESSORI_DI_AUTOVEICOLI'),risk: COMPANY_RISK.MEDIUM},
    {value:"46.2", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_ALLINGROSSO_DI_MATERIE_PRIME_AGRICOLE_E_DI_ANIMALI_VIVI'),risk: COMPANY_RISK.MEDIUM},
    {value:"46.3", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_ALLINGROSSO_DI_PRODOTTI_ALIMENTARI_BEVANDE_E_PRODOTTI_DEL_TABACCO'),risk: COMPANY_RISK.MEDIUM},
    {value:"46.4", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_ALLINGROSSO_DI_BENI_DI_CONSUMO_FINALE'),risk: COMPANY_RISK.MEDIUM},
    {value:"46.5", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_ALLINGROSSO_DI_APPARECCHIATURE_ICT'),risk: COMPANY_RISK.MEDIUM},
    {value:"46.6", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_ALLINGROSSO_DI_ALTRI_MACCHINARI_ATTREZZATURE_E_FORNITURE'),risk: COMPANY_RISK.MEDIUM},
    {value:"46.7", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_ALLINGROSSO_SPECIALIZZATO_DI_ALTRI_PRODOTTI'),risk: COMPANY_RISK.MEDIUM},
    {value:"46.9", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_ALLINGROSSO_NON_SPECIALIZZATO'),risk: COMPANY_RISK.MEDIUM},
    {value:"47.1", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_AL_DETTAGLIO_IN_ESERCIZI_NON_SPECIALIZZATI'),risk: COMPANY_RISK.MEDIUM},
    {value:"47.2", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_AL_DETTAGLIO_DI_PRODOTTI_ALIMENTARI_BEVANDE_E_TABACCO_IN_ESERCIZI_SPECIALIZZATI'),risk: COMPANY_RISK.MEDIUM},
    {value:"47.3", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_AL_DETTAGLIO_DI_CARBURANTE_PER_AUTOTRAZIONE_IN_ESERCIZI_SPECIALIZZATI'),risk: COMPANY_RISK.MEDIUM},
    {value:"47.4", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_AL_DETTAGLIO_DI_APPARECCHIATURE_INFORMATICHE_E_PER_LE_TELECOMUNICAZIONI_(ICT)_IN_ESERCIZI_SPECIALIZZATI'),risk: COMPANY_RISK.MEDIUM},
    {value:"47.5", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_AL_DETTAGLIO_DI_ALTRI_PRODOTTI_PER_USO_DOMESTICO_IN_ESERCIZI_SPECIALIZZATI'),risk: COMPANY_RISK.MEDIUM},
    {value:"47.6", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_AL_DETTAGLIO_DI_ARTICOLI_CULTURALI_E_RICREATIVI_IN_ESERCIZI_SPECIALIZZATI'),risk: COMPANY_RISK.MEDIUM},
    {value:"47.7", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_AL_DETTAGLIO_DI_ALTRI_PRODOTTI_IN_ESERCIZI_SPECIALIZZATI'),risk: COMPANY_RISK.MEDIUM},
    {value:"47.8", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_AL_DETTAGLIO_AMBULANTE'),risk: COMPANY_RISK.MEDIUM},
    {value:"47.9", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_AL_DETTAGLIO_AL_DI_FUORI_DI_NEGOZI_BANCHI_E_MERCATI'),risk: COMPANY_RISK.MEDIUM},
    {value:"68.1", label: t('ATECO_CODE.ATECO_CODE_COMPRAVENDITA_DI_BENI_IMMOBILI_EFFETTUATA_SU_BENI_PROPRI'),risk: COMPANY_RISK.MEDIUM},
    {value:"68.2", label: t('ATECO_CODE.ATECO_CODE_AFFITTO_E_GESTIONE_DI_IMMOBILI_DI_PROPRIETÀ_O_IN_LEASING'),risk: COMPANY_RISK.MEDIUM},
    {value:"68.3", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_IMMOBILIARI_PER_CONTO_TERZI'),risk: COMPANY_RISK.MEDIUM},
    {value:"73.1", label: t('ATECO_CODE.ATECO_CODE_PUBBLICITÀ'),risk: COMPANY_RISK.MEDIUM},
    {value:"73.2", label: t('ATECO_CODE.ATECO_CODE_RICERCHE_DI_MERCATO_E_SONDAGGI_DI_OPINIONE'),risk: COMPANY_RISK.MEDIUM},
    {value:"77.1", label: t('ATECO_CODE.ATECO_CODE_NOLEGGIO_DI_AUTOVEICOLI'),risk: COMPANY_RISK.MEDIUM},
    {value:"77.2", label: t('ATECO_CODE.ATECO_CODE_NOLEGGIO_DI_BENI_PER_USO_PERSONALE_E_PER_LA_CASA'),risk: COMPANY_RISK.MEDIUM},
    {value:"77.3", label: t('ATECO_CODE.ATECO_CODE_NOLEGGIO_DI_ALTRE_MACCHINE_ATTREZZATURE_E_BENI_MATERIALI'),risk: COMPANY_RISK.MEDIUM},
    {value:"79.1", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DELLE_AGENZIE_DI_VIAGGIO_E_DEI_TOUR_OPERATOR'),risk: COMPANY_RISK.MEDIUM},
    {value:"79.9", label: t('ATECO_CODE.ATECO_CODE_ALTRI_SERVIZI_DI_PRENOTAZIONE_E_ATTIVITÀ_CONNESSE'),risk: COMPANY_RISK.MEDIUM},
    {value:"81.2", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_PULIZIA_E_DISINFESTAZIONE'),risk: COMPANY_RISK.MEDIUM},
    {value:"82.2", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DEI_CALL_CENTER'),risk: COMPANY_RISK.MEDIUM},
    {value:"90.0", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_CREATIVE_ARTISTICHE_E_DI_INTRATTENIMENTO'),risk: COMPANY_RISK.MEDIUM},
    {value:"93.2", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_RICREATIVE_E_DI_DIVERTIMENTO'),risk: COMPANY_RISK.MEDIUM},
    {value:"06.1", label: t('ATECO_CODE.ATECO_CODE_ESTRAZIONE_DI_PETROLIO_GREGGIO'),risk: COMPANY_RISK.HIGH},
    {value:"06.2", label: t('ATECO_CODE.ATECO_CODE_ESTRAZIONE_DI_GAS_NATURALE'),risk: COMPANY_RISK.HIGH},
    {value:"25.4", label: t('ATECO_CODE.ATECO_CODE_FABBRICAZIONE_DI_ARMI_E_MUNIZIONI'),risk: COMPANY_RISK.HIGH},
    {value:"35.1", label: t('ATECO_CODE.ATECO_CODE_PRODUZIONE_TRASMISSIONE_E_DISTRIBUZIONE_DI_ENERGIA_ELETTRICA'),risk: COMPANY_RISK.HIGH},
    {value:"35.2", label: t('ATECO_CODE.ATECO_CODE_PRODUZIONE_DI_GAS_DISTRIBUZIONE_DI_COMBUSTIBILI_GASSOSI_MEDIANTE_CONDOTTE'),risk: COMPANY_RISK.HIGH},
    {value:"38.1", label: t('ATECO_CODE.ATECO_CODE_RACCOLTA_DEI_RIFIUTI'),risk: COMPANY_RISK.HIGH},
    {value:"38.2", label: t('ATECO_CODE.ATECO_CODE_TRATTAMENTO_E_SMALTIMENTO_DEI_RIFIUTI'),risk: COMPANY_RISK.HIGH},
    {value:"38.3", label: t('ATECO_CODE.ATECO_CODE_RECUPERO_DEI_MATERIALI'),risk: COMPANY_RISK.HIGH},
    {value:"39.0", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_DI_RISANAMENTO_E_ALTRI_SERVIZI_DI_GESTIONE_DEI_RIFIUTI'),risk: COMPANY_RISK.HIGH},
    {value:"41.2", label: t('ATECO_CODE.ATECO_CODE_COSTRUZIONE_DI_EDIFICI_RESIDENZIALI_E_NON_RESIDENZIALI'),risk: COMPANY_RISK.HIGH},
    {value:"42.1", label: t('ATECO_CODE.ATECO_CODE_COSTRUZIONE_DI_STRADE_E_FERROVIE'),risk: COMPANY_RISK.HIGH},
    {value:"42.2", label: t('ATECO_CODE.ATECO_CODE_COSTRUZIONE_DI_OPERE_DI_PUBBLICA_UTILITÀ'),risk: COMPANY_RISK.HIGH},
    {value:"42.9", label: t('ATECO_CODE.ATECO_CODE_COSTRUZIONE_DI_ALTRE_OPERE_DI_INGEGNERIA_CIVILE'),risk: COMPANY_RISK.HIGH},
    {value:"43.1", label: t('ATECO_CODE.ATECO_CODE_DEMOLIZIONE_E_PREPARAZIONE_DEL_CANTIERE_EDILE'),risk: COMPANY_RISK.HIGH},
    {value:"45.1", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_DI_AUTOVEICOLI'),risk: COMPANY_RISK.HIGH},
    {value:"45.2", label: t('ATECO_CODE.ATECO_CODE_MANUTENZIONE_E_RIPARAZIONE_DI_AUTOVEICOLI'),risk: COMPANY_RISK.HIGH},
    {value:"45.4", label: t('ATECO_CODE.ATECO_CODE_COMMERCIO_MANUTENZIONE_E_RIPARAZIONE_DI_MOTOCICLI_E_RELATIVE_PARTI_ED_ACCESSORI'),risk: COMPANY_RISK.HIGH},
    {value:"46.1", label: t('ATECO_CODE.ATECO_CODE_INTERMEDIARI_DEL_COMMERCIO'),risk: COMPANY_RISK.HIGH},
    {value:"55.1", label: t('ATECO_CODE.ATECO_CODE_ALBERGHI_E_STRUTTURE_SIMILI'),risk: COMPANY_RISK.HIGH},
    {value:"55.2", label: t('ATECO_CODE.ATECO_CODE_ALLOGGI_PER_VACANZE_E_ALTRE_STRUTTURE_PER_BREVI_SOGGIORNI'),risk: COMPANY_RISK.HIGH},
    {value:"55.3", label: t('ATECO_CODE.ATECO_CODE_AREE_DI_CAMPEGGIO_E_AREE_ATTREZZATE_PER_CAMPER_E_ROULOTTE'),risk: COMPANY_RISK.HIGH},
    {value:"55.9", label: t('ATECO_CODE.ATECO_CODE_ALTRI_ALLOGGI'),risk: COMPANY_RISK.HIGH},
    {value:"56.1", label: t('ATECO_CODE.ATECO_CODE_RISTORANTI_E_ATTIVITÀ_DI_RISTORAZIONE_MOBILE'),risk: COMPANY_RISK.HIGH},
    {value:"56.2", label: t('ATECO_CODE.ATECO_CODE_FORNITURA_DI_PASTI_PREPARATI_(CATERING)_E_ALTRI_SERVIZI_DI_RISTORAZIONE'),risk: COMPANY_RISK.HIGH},
    {value:"56.3", label: t('ATECO_CODE.ATECO_CODE_BAR_E_ALTRI_ESERCIZI_SIMILI_SENZA_CUCINA'),risk: COMPANY_RISK.HIGH},
    {value:"92.0", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_RIGUARDANTI_LE_LOTTERIE_LE_SCOMMESSE_LE_CASE_DA_GIOCO'),risk: COMPANY_RISK.HIGH},
    {value:"93.1", label: t('ATECO_CODE.ATECO_CODE_ATTIVITÀ_SPORTIVE'),risk: COMPANY_RISK.HIGH}
]