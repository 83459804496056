import React from "react";
import "./ErrorsHandler.scss";
import { connect } from "react-redux";
import * as actions from "../../core/store/actions/index";
import ErrorMessage from "../../core/types/UI/ErrorMessage";
import ToastMessageItem from "../ToastMessageItem/ToastMessageItem";
import ToastMessage, { TOAST_TYPE } from "../../core/types/UI/ToastMessage";
import ErrorIcon from '@material-ui/icons/Error';
import { ITEM_UI } from "../../core/constants/ItemUI";

function ErrorsHandler(props: {
  errors: ErrorMessage[];
  onCleanErrors: any;
  onHideError: any;
}) {

  const createErrorMessage = (error: ErrorMessage) => {
    const toastMessage: ToastMessage = {
      message: `Code Error : ${error.code} Message: ${error.description}`,
      icon:  <ErrorIcon />,
      autoCloseTime: 6000,
      showClose: error.visible,
      type: TOAST_TYPE.ERROR
    }
    return toastMessage;
  };;

  return props.errors && props.errors.length > 0 ? (
    <div className="errors-container">
      {props.errors.map((error) => {
        if (error.visible) {
          return (
            <ToastMessageItem
              toastMessage={createErrorMessage(error)}
            />
          );
        }
      })}
    </div>
  ) : (
    <></>
  );
}

const mapStateToProps = (state: any) => {
  return {
    errors: state.UI.errors,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onCleanErrors: () => {
      dispatch(actions.cleanErrors());
    },
    onHideError: () => {
      dispatch(actions.hideUiItem(ITEM_UI.TOAST_MESSAGE));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorsHandler);