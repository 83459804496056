import ToastMessage from "../UI/ToastMessage";
import { ErrorItem } from "../UI/ErrorItem";

export class UIState {
  showLoading: boolean;
  showOverlay: boolean;
  documentLoading: boolean;
  errors: ErrorItem[];
  toastMessage: ToastMessage;

  constructor(
    showLoading: boolean = false,
    showOverlay: boolean = false,
    documentLoading: boolean = false,
    errors: ErrorItem[] = [],
    toastMessage: ToastMessage = {}
  ) {
    this.showLoading = showLoading;
    this.showOverlay = showOverlay;
    this.documentLoading = documentLoading;
    this.errors = errors;
    this.toastMessage = toastMessage;
  }
}
