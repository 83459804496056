import "./SideMenu.scss";
import React from "react";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Header from "../Header/Header";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { NavLink } from "react-router-dom";
import { useStyles } from "../../hoc/Layout.style";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { ROUTES } from "../../core/constants/Routes";

export default function SideMenu(props: {
  onLogout: any;
  menuItems: { label: string; icon: any; route: string; link?: string }[];
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    props.onLogout();
  };

  return (
    <div>
      <CssBaseline />
      <Header
        headerClass={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: open,
          })}
        >
          <MenuIcon />
        </IconButton>
      </Header>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div>
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            {props.menuItems.map((item, index) =>
              item.link ? (
                <a href={item.link} className="nav-link" target="_blank">
                   <ListItem button>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItem>
                </a>
              ) : (
                <NavLink
                  key={index}
                  activeClassName="active-link"
                  className="nav-link"
                  to={item.route}
                >
                  <ListItem button>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItem>
                </NavLink>
              )
            )}
          </List>
          <Divider />
        </div>
        <List
          className={clsx(classes.drawer, {
            [classes.sideMenuFooterOpen]: open,
            [classes.sideMenuFooterClosed]: !open,
          })}
        >
          <NavLink
            className="nav-link"
            onClick={handleLogout}
            to={ROUTES.LOGIN}
          >
            <ListItem button>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Logout" />}
            </ListItem>
          </NavLink>
        </List>
      </Drawer>
    </div>
  );
}
