import { ROUTES } from "../../constants/Routes";

export default class TitlePage {
    titlePage?: TITLE
}

export enum TITLE {
    DASHBOARD = 'Dashboard',
    AML_VALIDATION = 'Validazione Aml',
    COMPANIES = 'Aziende Validate'
}

export const getTitlePage = (path: string) => {
    switch (path) {
        case ROUTES.DASHBOARD: return TITLE.DASHBOARD;
        case ROUTES.AML_VALIDATION: return TITLE.AML_VALIDATION;
        case ROUTES.COMPANIES: return TITLE.COMPANIES;
        default: return '';
      }
} 