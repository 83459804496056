import React from 'react';
import {historyColumns} from './HistorySchema';
import DataTable from "react-data-table-component";
import { historyTableStyle } from './HistoryTableStyle';

export default function HistoryTable(props: {
    dataSource?: any
}) {
    return (
            <DataTable
            responsive
            data={props.dataSource}
            columns={historyColumns()}
            customStyles={historyTableStyle}
          />
    )
}
