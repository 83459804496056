import THEME from "../../core/theme/theme";
import {MemberHistory} from "../../core/types/entity/CompanyMember";

import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CellTable from "../CellTable/CellTable";
import Tag from "../Tag/Tag";

export const historyColumns = () => [
  {
    name: "Data",
    selector: "startDate",
    sortable: true,
    cell: (row: MemberHistory) => (
      <CellTable data={row.startDate} />
    ),
  },
  {
    name: "Score",
    selector: "scoreValue",
    sortable: true,
    cell: (row: MemberHistory) =>
      row.scoreValue || row.scoreValue === 0 ? (
        <Tag tagValue={row.scoreValue} />
      ) : (
        <HourglassEmptyIcon htmlColor={THEME.palette?.primary?.hover} />
      ),
  },
];
