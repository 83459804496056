import * as actionTypes from "../../../types/actionTypes";

export const resetAmlState = () => {
    return {
      type: actionTypes.RESET_AML_STATE,
    };
  };

export const resetSetDataSuccess = () => {
  return {
    type: actionTypes.SET_DATA_SUCCESS,
    status: false
  }
}

export const resetDocumentSelected = () => {
  return {
    type: actionTypes.RESET_DOCUMENT_SELECTED,
  };
};