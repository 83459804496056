import "./Spacer.scss";

function Spacer(props: {
  size?: string;
  direction?: string;
  dynamic?: boolean;
}) {
  const className = `spacer ${props.size ? props.size : "xs"} ${
    props.direction ? props.direction : "horizontal"
  } ${props.dynamic ? "dynamic" : ""}`;

  return <div className={className} />;
}

export default Spacer;
