import AtecoCode from "../AtecoCode";
import DocumentCompany from "../DocumentCompany";
import AmlData from "../entity/AmlData";
import { Company } from "../entity/Company";
import { CompanyMember } from "../entity/CompanyMember";
import DashboardData from "../entity/DashboardData";

export class AmlState {
  data: AmlData;
  companiesSelected: Company[] = [];
  companiesIdsSelected: string[] = [];
  validateStatusSuccess?: boolean;
  setDataSuccess?: boolean;
  selectedRegistry?: CompanyMember;
  dashboard?: DashboardData;
  atecoCodeList?: AtecoCode[];
  sendEscalationSuccess?: boolean;
  documentSelected?: DocumentCompany | null;
  chamberViewSelected?: DocumentCompany | null;

  constructor(
    companiesIdsSelected: string[] = [],
    validateStatusSuccess: boolean = false,
    setDataSuccess: boolean = false,
    documentSelected: DocumentCompany | null = null,
    chamberViewSelected: DocumentCompany | null = null
  ) {
    this.data = new AmlData();
    this.companiesIdsSelected = companiesIdsSelected;
    this.validateStatusSuccess = validateStatusSuccess;
    this.setDataSuccess = setDataSuccess;
    this.documentSelected = documentSelected;
    this.chamberViewSelected = chamberViewSelected;
  }
}
