import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
//@ts-ignore
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ToastMessage, { TOAST_TYPE } from "../../core/types/UI/ToastMessage";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}), {index:1});

export default function ToastMessageItem(props: {
  toastMessage: ToastMessage;
  onClose?: any;
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(
    props.toastMessage?.message ? true : false
  );

  const getTypeToast = (type: TOAST_TYPE) => {
    switch (type) {
      case TOAST_TYPE.INFO:
        return "info";
      case TOAST_TYPE.SUCCESS:
        return "success";
      case TOAST_TYPE.WARNING:
        return "warning";
      case TOAST_TYPE.ERROR:
        return "error";
      default:
        return undefined;
    }
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    if(props.onClose) {
      props.onClose();
    }
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={props.toastMessage.autoCloseTime}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={getTypeToast(
            props.toastMessage.type ? props.toastMessage.type : TOAST_TYPE.ERROR
          )}
        >
          {props.toastMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
