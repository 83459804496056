import Theme from "../types/UI/Theme";

const THEME: Theme = {
    palette: {
      primary: {
        main: "#06ADF5",
        disabled: "",
        hover: "#2A80B5",
        selected: "",
      },
      secondary: {
        main: "#F66374",
        disabled: "",
        hover: "#BC4654",
        selected: "",
      },
      ternary: {
        main: "",
        disabled: "",
        hover: "",
        selected: "",
      },
      quaternary: {
        main: "",
        disabled: "",
        hover: "",
        selected: "",
      },
      white: "white",
      black: "#404B4C",
      transparent: "rgba(255,255,255,0)",
      transparent_black: 'rgba(0, 0, 0, 0.3)',
      grey: "rgba(245,245,245, 1)",
      silver: "rgba(240,240,240, 1)",
      red: "#BC4654",
      green: "#1E7310"
    },
    fontFamily: "Arial",
  };
  
  export default THEME;