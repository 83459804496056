import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import THEME from "../../core/theme/theme";
import { Trans, useTranslation } from "react-i18next";
import { companiesColumns } from "./CompaniesSchema";
import { companiesTableStyle } from "./CompaniesTableStyle";
import { CompanyMember } from "../../core/types/entity/CompanyMember";
import { TableConfig } from "../../core/types/UI/TableConfig";
import { Company } from "../../core/types/entity/Company";
import DocumentCompany, { DOCUMENT_COMPANY_TYPE } from "../../core/types/DocumentCompany";
import { ModalMessage } from "../../core/types/UI/ModalMessage";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import MemberTable from "../MembersTable/MemberTable";
import Spacer from "../Spacer/Spacer";
import Title from "../Title/Title";
import AtecoCodePanel from "../AtecoCodePanel/AtecoCodePanel";
import Modal from "../Modal/Modal";
import CompanyDocumentHandler from "../CompanyDocumentHandler/CompanyDocumentHandler";

export default function CompaniesTable(props: {
  title?: string;
  companies: Company[];
  onCompanySelected?: any;
  tableConfig?: TableConfig | undefined;
  onApproval?: any;
  onReject?: any;
  onHistoryClick?: any;
  onHandleEditAtecoCode?: any;
  showDeleteCompany?: boolean;
  onDeleteCompany?: any;
  onSetAtecoCode?: any;
  onHandleChamberView?: any;
  documents?: DOCUMENT_COMPANY_TYPE[];
  onEscalation?: any;
  escalationMailSuccess?: boolean;
  onDocumentSelected?: any;
  documentLoading: boolean;
  chamberView: DocumentCompany;
}) {
  const { t } = useTranslation();

  const [modalMessage, setModalMessage] = useState<ModalMessage>({
    showModal: false,
  });
  const [showCompanyDocumentHandler, setShowCompanyDocumentHandler] =
    useState(false);

  const [emailStatusModal, setEmailStatusModal] = useState<ModalMessage>({
    showModal: false,
  });

  const [companySelected, setCompanySelected] = useState<any>();

  const complianceModal = (
    <div style={companiesTableStyle.complianceModal}>
      <CheckCircleOutlineIcon />
      <span>
        <Trans>COMPANIES_TABLE.COMPLIANCE_MODAL.MESSAGE</Trans>
      </span>
    </div>
  );

  useEffect(() => {
    if (props.escalationMailSuccess) {
      const emailModal: ModalMessage = {
        showModal: true,
        modalTitle: t("COMPANIES_TABLE.COMPLIANCE_MODAL.TITLE"),
        modalComponent: complianceModal,
        overflowDisabled: true,
      };
      setEmailStatusModal(emailModal);
    }
  }, [props.escalationMailSuccess]);

  useEffect(() => {
    if (props.chamberView) {
      setShowCompanyDocumentHandler(true);
    }
  }, [props.chamberView]);

  const onSaveAtecoCode = (id: number, atecoCode: any) => {
    props.onSetAtecoCode(id, atecoCode);
    setModalMessage({
      showModal: false,
    });
  };

  const onHandleAtecoCode = (id: number) => {
    const modalMessageAteco: ModalMessage = {
      showModal: true,
      modalTitle: `${t("COMPANIES_TABLE.ATECO_MODAL.TITLE")}`,
      modalComponent: (
        <AtecoCodePanel
          id={id}
          onSaveCode={(id: number, atecoCode: any) =>
            onSaveAtecoCode(id, atecoCode)
          }
        />
      ),
    };
    setModalMessage(modalMessageAteco);
  };

  const onHandleChamberView = (company: Company) => {
    setCompanySelected(company);
    props.onHandleChamberView(company.id);
  };

  const onCloseDocumentHandlerModal = () => {
    setShowCompanyDocumentHandler(false)
  };

  const historyClick = (companyId: number, registry: CompanyMember) => {
    props.onHistoryClick(companyId, registry);
  };

  const ExpandableComponent = ({ data }: any) => (
    <div style={{ display: "flex" }}>
      <Spacer size="xxl" />
      {data.members && data.members.length > 0 ? (
        <MemberTable
          onHistoryClick={(row: CompanyMember) => historyClick(data.id, row)}
          dataSource={data.members}
          tableConfig={props.tableConfig}
        />
      ) : (
        <div
          style={{
            minHeight: 50,
            justifySelf: "center",
          }}
        >
          <Spacer direction="vertical" size="m" />
          <span style={{ color: THEME.palette?.primary?.hover }}>
            <Trans>COMPANIES_TABLE.NO_MEMBERS_MESSAGE</Trans>
          </span>
        </div>
      )}
    </div>
  );

  const createSchema = (tableConfig: TableConfig | undefined) => {
    return companiesColumns(
      props,
      tableConfig,
      t,
      onHandleAtecoCode,
      onHandleChamberView
    );
  };

  return (
    <Fragment>
      <Modal modalMessage={modalMessage} />
      <CompanyDocumentHandler
        company={companySelected}
        chamberView={props.chamberView}
        onClose={onCloseDocumentHandlerModal}
        onDocumentSelected={(documentType: DOCUMENT_COMPANY_TYPE) =>
          props.onDocumentSelected(companySelected.id, documentType)
        }
        show={showCompanyDocumentHandler}
        documentLoading={props.documentLoading}
      />

      <DataTable
        title={<Title title={props.title} />}
        responsive
        expandableRows
        persistTableHead
        expandableRowsComponent={<ExpandableComponent />}
        expandableRowExpanded={() => {
          return props.tableConfig?.isValidation === true;
        }}
        data={props.companies}
        noContextMenu
        customStyles={companiesTableStyle}
        columns={createSchema(props.tableConfig)}
      />
    </Fragment>
  );
}
