import { Fragment } from "react";
import "./CompanyInfoTooltip.scss";
import THEME from "./../../core/theme/theme";
import { INFOCERT_FLOW_TYPE } from "../../core/types/enum/INFOCERT_FLOW_TYPE";
import { Company } from "../../core/types/entity/Company";
import { Trans, useTranslation } from "react-i18next";
import Spacer from "../Spacer/Spacer";
import TooltipIcon from "./../Tooltip/TooltipIcon/TooltipIcon";
import InfoIcon from "@mui/icons-material/Info";

export default function CompanyInfoTooltip(props: { company: Company }) {
  const { t } = useTranslation();

  const setCompanyInfo = (company: Company) => {
    return (
      <div className="company-info-tooltip">
        <div className="company-info">
          <span>
            <Trans>COMPANIES_TABLE.COMPANY_INFO.ON_BOARDING_INFO</Trans>
          </span>
          <Spacer />
          <span className="info">{t(`ON_BOARDING_TYPE.${company.mode}`)}</span>
        </div>
        {company.mode !== INFOCERT_FLOW_TYPE.SINGLE && (
          <Fragment>
            <div className="company-info">
              <span>
                <Trans>COMPANIES_TABLE.COMPANY_INFO.GROUP_NAME</Trans>
              </span>
              <Spacer />
              <span className="info">{company.groupName}</span>
            </div>
            <div className="company-info">
              <span>
                <Trans>COMPANIES_TABLE.COMPANY_INFO.VAT_NUMBER</Trans>
              </span>
              <Spacer />
              <span className="info">{company.groupVAT}</span>
            </div>
          </Fragment>
        )}
        <div className="company-info">
          <span>
            <Trans>COMPANIES_TABLE.COMPANY_INFO.EXECUTOR_EMAIL</Trans>
          </span>
          <Spacer />
            <span className="info">{company.executorEmail}</span>
        </div>
      </div>
    );
  };

  return (
    <TooltipIcon
      title={setCompanyInfo(props.company)}
      icon={<InfoIcon htmlColor={THEME.palette?.primary?.hover} />}
    />
  );
}
