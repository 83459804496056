import "./DocumentPreview.scss";
import PdfViewer from "../PdfViewer/PdfViewer";
import { useTranslation } from "react-i18next/";
import DocumentCompany from "../../core/types/DocumentCompany";

export default function DocumentPreview(props: {
  document: DocumentCompany;
  companyVat?: string;
  vatNumber?: string
}) {
  const { t } = useTranslation();
  return (
    <div className="document-preview-container">
      {props.document && (props.vatNumber || props.companyVat) && (
        <PdfViewer
          fileName={`${t("PDF_VIEWER.CHAMBER_VIEW_FILE_NAME")}-${
            props.vatNumber ? props.vatNumber : ""
          }`}
          pdf={`data:application/pdf;base64,${props.document.fileContent}`}
        />
      )}
    </div>
  );
}
