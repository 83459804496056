import { ITEM_UI } from "../../../constants/ItemUI";
import * as actionTypes from "../../../types/actionTypes";

export const showUiItem = (type: ITEM_UI, message?: any) => {
  return (dispatch: any) => {
    switch (type) {
      case ITEM_UI.LOADING:
        dispatch(handleLoading(true));
        break;
      case ITEM_UI.DOCUMENT_LOADING:
        dispatch(handleDocumentLoading(true));
        break;
      case ITEM_UI.TOAST_MESSAGE:
        dispatch(handleToast(message));
        break;
      case ITEM_UI.MODAL:
        dispatch(handleModal(true, message));
        break;
      default:
        return null;
    }
  };
};

export const hideUiItem = (type: ITEM_UI) => {
  return (dispatch: any) => {
    switch (type) {
      case ITEM_UI.LOADING:
        dispatch(handleLoading(false));
        break;
      case ITEM_UI.DOCUMENT_LOADING:
        dispatch(handleDocumentLoading(false));
        break;
      case ITEM_UI.TOAST_MESSAGE:
        dispatch(handleToast({}));
        break;
      case ITEM_UI.MODAL:
        dispatch(handleModal(false));
        break;
      default:
        return null;
    }
  };
};

const handleLoading = (show: boolean) => {
  return {
    type: actionTypes.SHOW_LOADING,
    showLoading: show,
  };
};

const handleDocumentLoading = (documentLoading: boolean) => {
  return {
    type: actionTypes.SHOW_DOCUMENT_LOADING,
    documentLoading,
  };
};

const handleToast = (message?: any) => {
  return {
    type: actionTypes.SHOW_TOAST,
    toastMessage: message,
  };
};

const handleModal = (show: boolean, message?: any) => {
  return {
    type: actionTypes.SHOW_MODAL,
    showModal: show,
    message: message,
  };
};

export const setErrors = (errors: any) => {
  return {
    type: actionTypes.SET_ERRORS,
    errors,
  };
};

export const cleanErrors = () => {
  return {
    type: actionTypes.CLEAN_ERRORS,
  };
};
