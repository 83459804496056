//AML
export const RESET_AML_STATE = 'RESET_AML_STATE';
export const SET_DATA_SUCCESS = 'SET_DATA_SUCCESS';
export const RESET_DOCUMENT_SELECTED = 'RESET_DOCUMENT_SELECTED';

//DASHBOARD
export const SET_DASHBOARD_SUCCESS = 'SET_DASHBOARD_SUCCESS';

//Companies
export const SET_COMPANIES_SUCCESS = 'SET_COMPANIES_SUCCESS';
export const ADD_COMPANY = 'ADD_COMPANY';
export const REMOVE_COMPANY = 'REMOVE_COMPANY';
export const SET_COMPANY_MEMBER_SUCCESS = 'SET_COMPANY_MEMBER_SUCCESS';
export const SET_VALIDATION_SUCCESS = 'SET_VALIDATION_SUCCESS';
export const SET_HISTORY_SUCCESS = 'SET_HISTORY_SUCCESS';
export const SET_SELECTED_REGISTRY = 'SET_SELECTED_REGISTRY';
export const SET_OLD_COMPANIES_SUCCESS = 'SET_OLD_COMPANIES_SUCCESS';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const SET_UPDATED_COMPANIES_SUCCESS = 'SET_UPDATED_COMPANIES_SUCCESS';
export const SET_DOCUMENT_COMPANY_SUCCESS = 'SET_DOCUMENT_COMPANY_SUCCESS';
export const SET_CHAMBER_VIEW_SUCCESS = 'SET_CHAMBER_VIEW_SUCCESS';
export const SEND_ESCALATION_SUCCESS = 'SEND_ESCALATION_SUCCESS';
export const SET_DOCUMENT_SELECTED_SUCCESS = 'SET_DOCUMENT_SELECTED_SUCCESS'

//UI
export const SHOW_LOADING = 'SHOW_LOADING';
export const SHOW_DOCUMENT_LOADING = 'SHOW_DOCUMENT_LOADING';
export const SHOW_TOAST = 'SHOW_TOAST';
export const SHOW_MODAL = 'SHOW_MODAL';
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAN_ERRORS = 'CLEAN_ERRORS';
