import "./ValidationView.scss";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../core/store/actions";
import { AmlState } from "../../core/types/state/AmlState";
import { Company } from "../../core/types/entity/Company";

import { Card } from "@material-ui/core";
import CompaniesTable from "../../components/CompaniesTable/CompaniesTable";
import Spacer from "../../components/Spacer/Spacer";
import Placeholder from "../../components/Placeholder/Placeholder";
import Modal from "../../components/Modal/Modal";
import { ModalMessage } from "../../core/types/UI/ModalMessage";
import CompaniesList from "../../components/CompaniesList/CompaniesList";
import { validationConfig } from "../../core/utilities/configurations";
import { CompanyMember } from "../../core/types/entity/CompanyMember";
import DocumentCompany, {
  DOCUMENT_COMPANY_TYPE,
} from "../../core/types/DocumentCompany";
import { downloadDocument } from "../../core/utilities/documentHandler";

function ValidationView(props: {
  onAddCompany?: any;
  onRemoveCompany?: any;
  aml?: AmlState;
  onResetAmlState?: any;
  onGetNewCompanies?: any;
  onValidateCompaniesStatus?: any;
  onSetAtecoCode?: any;
  setDataSuccess?: boolean;
  onResetSetDataSuccess?: any;
  onGetChamberByCompany?: any;
  onGetHistory?: any;
  onEscalation: any;
  sendEscalationSuccess: boolean;
  onDocumentSelected?: any;
  documentSelected?: DocumentCompany;
  chamberViewSelected: DocumentCompany;
  resetDocumentSelected: any;
  documentLoading: boolean;
  startDownload: any;
}) {
  const [modalMessage, setModalMessage] = useState<ModalMessage>({
    showModal: false,
  });

  useEffect(() => {
    props.onResetAmlState();
    props.onGetNewCompanies();
  }, []);

  useEffect(() => {
    if (props.documentSelected && props.documentSelected.fileContent) {
      downloadDocument(props.documentSelected);
    }
  }, [props.documentSelected]);

  useEffect(() => {
    if (props.setDataSuccess) {
      if (props.setDataSuccess) {
        props.onResetSetDataSuccess();
        props.onGetNewCompanies();
      }
    }
  }, [props.setDataSuccess]);

  useEffect(() => {
    if (props.aml?.validateStatusSuccess) {
      props.onResetAmlState();
      props.onGetNewCompanies();
    }
  }, [props.aml?.validateStatusSuccess]);

  const onHandleValidate = (company: Company, approve: boolean) => {
    props.onValidateCompaniesStatus([company.id], approve);
    setModalMessage({ showModal: false });
    props.onResetAmlState();
    props.onGetNewCompanies();
  };
  const onHandleClose = (company: Company) => {
    props.onRemoveCompany(company.id);
  };

  const onHandleApproval = (company: Company) => {
    props.onAddCompany(company);
    onShowModal(true, company);
  };

  const onHandleReject = (company: Company) => {
    props.onAddCompany(company);
    onShowModal(false, company);
  };

  const onHandleDocumentSelected = (
    id: number,
    documentType: DOCUMENT_COMPANY_TYPE
  ) => {
    props.onDocumentSelected(id, documentType);
  };

  const onShowModal = (approve: boolean, company: Company) => {
    const modalMessageConfirm: ModalMessage = {
      showModal: true,
      titleClassName: "alert-title-modal",
      onCancel: () => {
        onHandleClose(company);
      },
      modalTitle: `Conferma ${approve ? "Approvazione" : "Respinta"}`,
      message: `Confermi di voler ${
        approve ? "approvare" : "respingere"
      } l'azienda selezionata?`,
      modalComponent: props.aml?.companiesSelected &&
        props.aml.companiesSelected.length > 0 && (
          <CompaniesList companiesList={props.aml?.companiesSelected} />
        ),
      onConfirm: () => onHandleValidate(company, approve),
      onClose: () => {
        onHandleClose(company);
      },
    };

    setModalMessage(modalMessageConfirm);
  };

  const onHandleSetAtecoCode = (id: number, atecoCode: any) => {
    props.onSetAtecoCode(id, atecoCode);
  };

  const onHandleChamberView = (id: number) => {
    props.onGetChamberByCompany(id);
  };

  const onHistoryClick = (companyId: number, row: CompanyMember) => {
    props.onGetHistory(companyId, row);
  };

  const onEscalation = (row: Company) => {
    props.onEscalation(row.id);
  };

  return (
    <Card className="validation-view-container">
      <div>
        <Modal modalMessage={modalMessage} />
      </div>
      <Spacer direction="vertical" size="m" />
      <div className="validation-table-container">
        {props.aml &&
        props.aml.data?.companies &&
        props.aml.data?.companies.length > 0 ? (
          <CompaniesTable
            title="Validazione Aml"
            tableConfig={validationConfig}
            companies={props.aml.data.companies}
            onApproval={(row: Company) => onHandleApproval(row)}
            onReject={(row: Company) => onHandleReject(row)}
            onSetAtecoCode={(id: number, atecoCode: any) =>
              onHandleSetAtecoCode(id, atecoCode)
            }
            onEscalation={(row: Company) => onEscalation(row)}
            escalationMailSuccess={props.sendEscalationSuccess}
            onHistoryClick={onHistoryClick}
            chamberView={props.chamberViewSelected}
            onHandleChamberView={(id: number) => onHandleChamberView(id)}
            onDocumentSelected={(
              id: number,
              documentType: DOCUMENT_COMPANY_TYPE
            ) => onHandleDocumentSelected(id, documentType)}
            documentLoading={props.documentLoading}
          />
        ) : (
          <Placeholder message="Nessuna azienda da validare" />
        )}
      </div>
    </Card>
  );
}

const mapStateToProps = (state: any) => {
  return {
    aml: state.aml,
    setDataSuccess: state.aml.setDataSuccess,
    sendEscalationSuccess: state.aml.sendEscalationSuccess,
    documentSelected: state.aml.documentSelected,
    chamberViewSelected: state.aml.chamberViewSelected,
    documentLoading: state.UI.documentLoading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onAddCompany: (company: Company) => {
      dispatch(actions.addCompany(company));
    },
    onRemoveCompany: (companyId: number) => {
      dispatch(actions.removeCompany(companyId));
    },
    setSelectedRegistry: (registry?: CompanyMember) => {
      dispatch(actions.setSelectedRegistry(registry));
    },
    onGetNewCompanies: () => {
      dispatch(actions.getNewCompanies());
    },
    onValidateCompaniesStatus: (ids: number[], approved: boolean) => {
      dispatch(actions.setCompanyStatus(ids, approved));
    },
    onResetAmlState: () => {
      dispatch(actions.resetAmlState());
    },
    onSetAtecoCode: (companyId: number, atecoCode: any) => {
      dispatch(actions.setAtecoCode(companyId, atecoCode));
    },
    onResetSetDataSuccess: () => {
      dispatch(actions.resetSetDataSuccess());
    },
    onGetChamberByCompany: (id: number) => {
      dispatch(actions.getChamberByCompany(id));
    },
    onEscalation: (companyId: number) => {
      dispatch(actions.sendEscalationEmail(companyId));
    },
    onDocumentSelected: (
      companyId: number,
      documentType: DOCUMENT_COMPANY_TYPE
    ) => {
      dispatch(actions.getDocumentCompany(companyId, documentType));
    },
    resetDocumentSelected: () => {
      dispatch(actions.resetDocumentSelected());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationView);
