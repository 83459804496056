import { Divider } from "@material-ui/core";
import { Fragment } from "react";
import Spacer from "../Spacer/Spacer";
import "./Title.scss";
export default function Title(props: {
  title?: string;
  className?: string;
  disableDivider?: boolean;
}) {
  return (
    <Fragment>
      <div className="title-row">
        <span className={`title ${props.className ? props.className : ""}`}>
          {props.title}
        </span>
        {!props.disableDivider && (
          <>
            <Spacer size="s" direction="vertical" />
            <Divider />
            <Spacer size="s" direction="vertical" />
          </>
        )}
      </div>
    </Fragment>
  );
}
