import { COMPANY_RISK } from "../types/entity/Company";
import ThumbUpAlt from "@material-ui/icons/ThumbUpAlt";
import ThumbDownAlt from "@material-ui/icons/ThumbDownAlt";
import TimerIcon from "@material-ui/icons/Timer";
import THEME from "../theme/theme";

export const setTooltipRiskColor = (risk: COMPANY_RISK) => {
    switch (risk) {
      case COMPANY_RISK.LOW:
        return "green";
      case COMPANY_RISK.MEDIUM:
        return "orange";
      case COMPANY_RISK.HIGH:
        return "red";
      default:
        return "white";
    }
  };

  export const setTooltipValueColor = (value: boolean | null) => {
    switch (value) {
      case false:
        return "green";
      default:
        return "red";
    }
  };

  export const getIconStatus = (approved: boolean | null) => {
    switch (approved) {
      case true:
        return <ThumbUpAlt htmlColor={THEME.palette?.green} />;
      case false:
        return <ThumbDownAlt htmlColor={THEME.palette?.red} />;
      default:
        return <TimerIcon htmlColor={THEME.palette?.primary?.hover} />;
    }
  };

  export const getBooleanValue = (value: boolean | null, t:any) => {
    switch (value) {
      case true:
      return t("COMPANIES_TABLE.BOOLEAN_VALUE_TRUE")
      case false:
        return t("COMPANIES_TABLE.BOOLEAN_VALUE_FALSE")
      default:
        return t("COMPANIES_TABLE.BOOLEAN_VALUE_NULL");
    }
  }

  export const getSelectedValue = (dataSource: any[], selectedValue?: any) => {
    return dataSource.find(
      (item: any) => item.value === selectedValue
    );
  }
