export default class DocumentCompany {
    extension: string = "";
    fileContent: string = "";
    fileName: string = "";
    mimeType: string = "";
    type?: DOCUMENT_COMPANY_TYPE;
}

export enum DOCUMENT_COMPANY_TYPE {
    ALL = 'ALL',
    CHAMBER = 'CHAMBER',
    CONTRACT = 'CONTRACT',
    SIGNED= 'SIGNED',
    IDENTITIES = 'IDENTITIES',
    SIGN_POWER = 'SIGN_POWER'
}