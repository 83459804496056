import React, { useEffect } from "react";
import "./Modal.scss";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";
import { ModalMessage } from "../../core/types/UI/ModalMessage";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Spacer from "../Spacer/Spacer";
import { useTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Spacer direction="vertical" size="m" />
      <Divider light />
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Modal(props: {
  children?: any;
  modalMessage?: ModalMessage;
  fullWidth?: boolean;
}) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(
    props.modalMessage?.showModal ? props.modalMessage.showModal : false
  );

  useEffect(() => {
    if (props.modalMessage?.showModal === true) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.modalMessage]);

  const handleClose = () => {
    setOpen(false);
    if (props.modalMessage?.onClose) {
      props.modalMessage?.onClose();
    }
    if (props.modalMessage?.onCancel) {
      props.modalMessage?.onCancel();
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span className={props.modalMessage?.titleClassName}>
            {props.modalMessage?.modalTitle}
          </span>
        </DialogTitle>
        <DialogContent
          className={
            props.modalMessage && props.modalMessage?.overflowDisabled
              ? "overflow-disabled"
              : ""
          }
        >
          {props.modalMessage?.message && (
            <div className="modal-message-container">
              <span className="modal-message">
                {props.modalMessage?.message}
              </span>
              <Spacer direction="vertical" size="m" />
            </div>
          )}
          {props.modalMessage?.modalComponent}
          {props.children}
        </DialogContent>
        <DialogActions className="dialog-actions-container">
          {props.modalMessage?.onCancel && (
            <Button
              autoFocus
              onClick={handleClose}
              color="primary"
            >
              {props.modalMessage?.textConfirm
                ? props.modalMessage?.textConfirm
                : t("UI.MODAL.CANCEL")}
            </Button>
          )}
          {props.modalMessage?.onConfirm && (
            <Button
              autoFocus
              onClick={props.modalMessage?.onConfirm}
              color="primary"
            >
              {props.modalMessage?.textConfirm
                ? props.modalMessage?.textConfirm
                : t("UI.MODAL.CONFIRM")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
