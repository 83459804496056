import i18n from "i18next";
//import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import EN from './core/translation/en/en.json';
import IT from './core/translation/it/it.json';

i18n
    //.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            en: {
                translation: EN
            },
            it: {
                translation: IT
            }
        },
        lng: "it",
        fallbackLng: "en",

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
