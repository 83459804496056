import "./Placeholders.scss";

export default function Placeholder(props: {
  message?: string;
  children?: any;
  className?: string;
}) {
  return (
    <div
      className={`placeholder-container ${
        props.className ? props.className : ""
      }`}
    >
      <span className="placeholder-message">{props.message}</span>

      {props.children}
    </div>
  );
}
