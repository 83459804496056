import { useState, useEffect } from "react";
import "./Layout.scss";
import Routes from "../navigation/routes";
import * as actions from "../core/store/actions/index";
import { useLocation } from "react-router-dom";
import { useStyles } from "./Layout.style";
import { connect } from "react-redux";
//import { oktaAuth } from "../okta";
import { useOktaAuth } from '@okta/okta-react';
import { oktalogout } from "../okta";
import { MenuItems } from "../core/constants/MenuItems";
import { AmlState } from "../core/types/state/AmlState";
import { ITEM_UI } from "../core/constants/ItemUI";

import ToastMessage from "../core/types/UI/ToastMessage";
import ErrorsHandler from "../components/ErrorsHandler/ErrorsHandler";
import SideMenu from "../components/SideMenu/SideMenu";
import OverlaySpinner from "../components/OverlaySpinner/OverlaySpinner";
import Modal from "../components/Modal/Modal";
import FeedbackHandler from "../components/FeedbackHandler/FeedbackHandler";
import { getTitlePage } from "../core/types/UI/TitlePage";


const Layout = (props: {
  showLoading?: any;
  aml?: AmlState;
  toastMessage?: ToastMessage;
  onCloseToast?: any;
}) => {
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    let title = getTitlePage(location.pathname);
    document.title = `Primaton Desk ${title ? "-" : ""} ${title}`;
  }, [location]);

  const handleLogout = () => {
    oktalogout();
  };

  const onCloseToast = () => {
    props.onCloseToast();
  };

  const uiItems = (
    <>
      <OverlaySpinner showLoading={props.showLoading} />
      <ErrorsHandler />
    </>
  );

  const sideMenu = (
    <SideMenu onLogout={handleLogout} menuItems={MenuItems} />
  );

  return (
    <div className={classes.root}>
      {uiItems}
      {sideMenu}
      {props.toastMessage && (
        <FeedbackHandler
          toastMessage={props.toastMessage}
          onClose={onCloseToast}
        />
      )}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Routes />
        <Modal />
      </main>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    showLoading: state.UI.showLoading,
    toastMessage: state.UI.toastMessage,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onCloseToast: () => {
      dispatch(actions.hideUiItem(ITEM_UI.TOAST_MESSAGE));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
