import "./Tag.scss";

export default function Tag(props: { tagValue: number }) {
  const getTagStyle = (value: number) => {
    switch (true) {
      case value < 50:
        return "green";
      case value >= 50 && value < 70:
        return "orange";
      case value >= 70:
        return "red";
    }
  };

  return (
    <span className={`tag ${getTagStyle(props.tagValue)}`}>
      {Math.round(props.tagValue * 10) / 10}
    </span>
  );
}
