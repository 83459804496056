import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 10,
      color: "#fff",
    },
  }), {index:1}
);

export default function Overlay(props: {
  children?: any;
  showOverlay?: boolean;
}) {
  const classes = useStyles();

  return (
    <Backdrop
      className={classes.backdrop}
      open={props.showOverlay ? props.showOverlay : false}
      >
      {props.children}
    </Backdrop>
  );
}
