import DocumentCompany, {
  DOCUMENT_COMPANY_TYPE,
} from "../types/DocumentCompany";
import download from "downloadjs";

export const downloadDocument = (file: DocumentCompany) => {
  let url = `data:${file.mimeType};base64,${file.fileContent}`;
  if (file && file.fileContent) {
    download(url, file.fileName);
  }
};

export const setDocumentNode = (documentType: DOCUMENT_COMPANY_TYPE) => {
  switch (documentType) {
    case DOCUMENT_COMPANY_TYPE.CHAMBER:
      return "chamber";

    case DOCUMENT_COMPANY_TYPE.SIGN_POWER:
      return "signaturePower";

    case DOCUMENT_COMPANY_TYPE.CONTRACT:
      return "contract";

    case DOCUMENT_COMPANY_TYPE.SIGNED:
      return "contract";

    case DOCUMENT_COMPANY_TYPE.ALL:
    case DOCUMENT_COMPANY_TYPE.IDENTITIES:
      return "documentsZip";

    default:
      return "";
  }
};
