import  {MutableRefObject, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import {config_okta} from '../../okta';
// @ts-ignore
import OktaSignIn from '@okta/okta-signin-widget';

export const  OktaLogin = (props:{
    nextRoute: string;
}) => {

    const history = useHistory();
    const widgetRef: MutableRefObject<any> = useRef();
    const { authState, oktaAuth } = useOktaAuth();

    const { issuer, clientId, redirectUri, scope } = config_okta;
    const signInWidgetConfig = {
    // Enable or disable widget functionality with the following options. Some of these features require additional configuration in your Okta admin settings. Detailed information can be found here: https://github.com/okta/okta-signin-widget#okta-sign-in-widget
    // Look and feel changes:
    logo: '//logo.clearbit.com/okta.com', // Try changing "okta.com" to other domains, like: "workday.com", "splunk.com", or "delmonte.com"
    language: 'it',                       // Try: [fr, de, es, ja, zh-CN] Full list: https://github.com/okta/okta-signin-widget#language-and-text
    i18n: {
      //Overrides default text when using English. Override other languages by adding additional sections.
      'en': {
        'primaryauth.title': 'Primaton Desk Login',   // Changes the sign in text
        'primaryauth.submit': 'Sign In',  // Changes the sign in button
        // More e.g. [primaryauth.username.placeholder,  primaryauth.password.placeholder, needhelp, etc.].
        // Full list here: https://github.com/okta/okta-signin-widget/blob/master/packages/@okta/i18n/dist/properties/login.properties
      }
    },
    // Changes to widget functionality
    features: {
      //registration: true,                 // Enable self-service registration flow
      rememberMe: false,                   // Setting to false will remove the checkbox to save username
      multiOptionalFactorEnroll: false,  // Allow users to enroll in multiple optional factors before finishing the authentication flow.
      //selfServiceUnlock: true,          // Will enable unlock in addition to forgotten password
      //smsRecovery: true,                // Enable SMS-based account recovery
      //callRecovery: true,               // Enable voice call-based account recovery
      router: true,                       // Leave this set to true for the API demo
    },
    baseUrl: issuer.split('/oauth2')[0], //'https://live-widget.oktapreview.com',
    clientId: clientId, //'0oaexo9c530ZUVuOj0h7',
    redirectUri: redirectUri,//'https://developer.okta.com/live-widget',
    scopes: scope,
    authParams: {
      issuer: issuer, //'https://live-widget.oktapreview.com/oauth2/ausexqn31sz3HMxdf0h7',
      responseType: ['id_token', 'token'],
      scopes: scope, //['openid', 'email', 'profile'],
    },
  };

  console.log(signInWidgetConfig);
  var signInWidget = new OktaSignIn(signInWidgetConfig);

  function widgetSuccessCallback(res:any) {
    console.log(res);
    var key = '';
    if (res[0]) {
      key = Object.keys(res[0])[0];
      signInWidget.tokenManager.add(key, res[0]);
    }
    if (res[1]) {
      key = Object.keys(res[1])[0];
      signInWidget.tokenManager.add(key, res[1]);
    }

    if (res.status === 'SUCCESS') {
      console.log('response ', res);
      let tokens = res.tokens;
      oktaAuth.tokenManager.setTokens(tokens);
      //oktaAuth.tokenManager.add('idToken', tokens.idToken);
      //oktaAuth.tokenManager.add('accessToekn', tokens.accessToken);
    }
  }

  function widgetErrorCallback (err:any) {
    //TODO: Deal with ERRORS
    console.log("ERROR ", err);
  }

  useEffect(() => {
      console.log("authState ",authState);
    if (!authState.isAuthenticated) {
        // When user isn't authenticated

      }
      else {
          //console.log("authState is authenticated");
          oktaAuth.getUser().then((info) => {
            console.log("okta is authenticated", info);
            history.push(props.nextRoute);
        });
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, oktaAuth]); // update id authstate changes

  useEffect(() => {
    if (widgetRef.current){
      console.log(signInWidgetConfig);
      // widget log in
       signInWidget.renderEl({el: widgetRef.current}, widgetSuccessCallback, widgetErrorCallback);
    }
    return ()=>{
      // call remove
      signInWidget.remove();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth]);

  return (
    <div>
      {
      <div ref={widgetRef} />
      }
    </div>
  );
};


export default OktaLogin;
