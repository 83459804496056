import { OktaAuth } from '@okta/okta-auth-js';
import { ROUTES } from "./core/constants/Routes";

const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const OIDC_CLIENT = process.env.REACT_APP_OKTA_OIDC_CLIENT;

const PROTOCOL = window.location.protocol;
const ISSUER = `https://${OKTA_DOMAIN}/oauth2/default`; //${PROTOCOL}
const HOST = window.location.host;
const SCOPES = 'openid profile email';

const CALLBACK_PATH = ROUTES.OKTA_CALLBACK; //'/login/callback';
const CALLBACK_LOGOUT = ROUTES.LOGIN;
const REDIRECT_URI = `${PROTOCOL}//${HOST}${CALLBACK_PATH}`;
//export const LOGOUT_REDIRECT_URI = `http://${HOST}${CALLBACK_LOGOUT}`;;
export const LOGOUT_REDIRECT_URI = `${PROTOCOL}//${HOST}${CALLBACK_LOGOUT}`;
// postLogoutRedirectUri: window.location.origin + ROUTES.OKTA_LOGOUT

//TODO: fare attenzione al postLogoutRedirect
// in questo momento è su window.location.origin
// se cambia, bisogna allineare l'app Okta PrimatonDesk
export const config_okta = {
    issuer: ISSUER,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    scope: SCOPES.split(/\s+/),
    postLogoutRedirectUri: window.location.origin + ROUTES.LOGIN,
    //pkce: false,
    responseType: 'code',
    tokenManager: {
      storage: 'sessionStorage'
    },
    oidcClient: OIDC_CLIENT
  };

  console.log("config okta");
  console.log(config_okta);

  export const oktalogout = async() => await oktaAuth.signOut();

  export const oktaAuth = new OktaAuth(config_okta);

  export const checkOktaGroup = (groupsList:string[])=>{
    for (var i=0; i<groupsList.length; i++){
      if (groupsList[i]==="PRIMATON")
      return true;
    }
    return false;

  }